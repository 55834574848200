import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { read_cookie } from 'sfcookies';
import PropTypes from 'prop-types';

import ConnexionPage from '../../containers/ConnexionPage';
import Footer from '../Footer';
import Header from '../Header';
import InfosConsultantForm from '../../containers/InfosConsultantForm';
import InfosConsultantPage from '../../containers/InfosConsultantPage';
import InfosPage from '../InfosPage';
import LegalMentions from '../LegalMentions';
import MeetingConsultantPage from '../../containers/MeetingConsultantPage';
import MeetingPage from '../../containers/MeetingPage';
// import ReportsPage from '../ReportsPage';
import UnauthorizedPage from '../UnauthorizedPage';

import './styles.scss';
import Loader from '../Loader';

const App = ({
    isAuthentified,
    isConnexionLoading,
    successLogin,
}) => {
    const token = read_cookie('jwt');

    if (token.length !== 0) {
        successLogin();
    }

    return (
    <Router>
        <div className="app-container">
        <Header />
            <div className="main-container">
                <Routes>
                    <Route path="/" element={<ConnexionPage />} />
                    <Route path="/mentions-legales" element={<LegalMentions />} />
                    
                    {isConnexionLoading
                    && <Route path="/:slug" element={<Loader />} />}
                    
                    {isAuthentified
                    && !isConnexionLoading
                    && (
                        <>
                            <Route path="/consultants" element={<InfosPage />} />
                            {/* Formulaire d'ajout de nouveau consultant (props vides) */}
                            <Route path="/consultants/add" element={<InfosConsultantForm />} />
                            {/* Consultation de la fiche-infos d'un consultant */}
                            <Route path="/consultants/:slug" element={<InfosConsultantPage />} />
                            <Route path="/reunion" element={<MeetingPage />} />
                            <Route path="/reunion/:slug" element={<MeetingConsultantPage />} />
                            {/* <Route path="/comptes-rendus" element={<ReportsPage />} /> */}
                        </>
                    )}
                    {!isAuthentified
                    && <Route path="/:slug" element={<UnauthorizedPage />} />}

                </Routes>
            </div>
        <Footer />
        </div>
    </Router>
)};

App.propTypes = {
    isAuthentified: PropTypes.bool.isRequired,
    isConnexionLoading: PropTypes.bool.isRequired,
    successLogin: PropTypes.func.isRequired,
};


// App.defaultProps = {
// };

export default App;
