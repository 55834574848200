import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@mui/material';

import ConsultantsListTable from '../../../containers/InfosPage/ConsultantsList/ConsultantsListTable';
import TabPanel from './TabPanel';

import { getCompany } from '../../../scripts/functions';

import './styles.scss';

const ConsultantsList = ({
    setIsConsultantsListLoading,
    companyValue,
    loadConsultantsList,
    crealisConsultantsArray,
    portsideConsultantsArray,
    hcsConsultantsArray,
}) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        loadConsultantsList(companyValue);
        return () => {
            setIsConsultantsListLoading(true);
        }
    },
        []);

    let consultantsArray = [];

    switch (companyValue) {
        case 1:
            consultantsArray = [...crealisConsultantsArray]
            break;
        case 3:
            consultantsArray = [...portsideConsultantsArray]
            break;
        case 4:
            consultantsArray = [...hcsConsultantsArray]
            break;
        default:
    }
    return (
        <div className="consultants-list-container">
            <h2 className="consultants-list-title">{getCompany(companyValue)}</h2>
            {/* Compter le nombre de lignes du tableau arrivant
            Pour Créalis : prévoir un "dont Créalis/Portside" */}
            <h3 className="consultants-list-count">Nombre de consultants actifs : {consultantsArray.filter(consultant => consultant.is_active == 1).length}</h3>
            <div className='consultants-list-table-container'>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="toggle-active-inactive-consultants"
                    textColor="secondary"
                    indicatorColor="secondary"
                    sx={{ alignSelf: "flex-end" }}
                >
                    <Tab label="Actifs" id={'active-consultants-tab'} aria-controls={'simple-tabpanel-1'} />
                    <Tab label="Archivés" id={'inactive-consultants-tab'} aria-controls={'simple-tabpanel-2'} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <ConsultantsListTable
                        consultantsList={consultantsArray.filter(consultant => consultant.is_active == 1)}
                        isActiveConsultants
                        companyValue={companyValue}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ConsultantsListTable
                        consultantsList={consultantsArray.filter(consultant => consultant.is_active == 0)}
                        isInactiveConsultants
                        companyValue={companyValue}
                    />
                </TabPanel>
            </div>
            <button className='consultants-list-add-button'>
                <Link to="/consultants/add">
                    Créer un nouveau consultant
                </Link>
            </button>
        </div>
    );
};

ConsultantsList.propTypes = {
    companyValue: PropTypes.number.isRequired,
    setIsConsultantsListLoading: PropTypes.func.isRequired,
    loadConsultantsList: PropTypes.func.isRequired,
    crealisConsultantsArray: PropTypes.array.isRequired,
    portsideConsultantsArray: PropTypes.array.isRequired,
    hcsConsultantsArray: PropTypes.array.isRequired,
};


// ConsultantsList.defaultProps = {
// };

export default ConsultantsList;