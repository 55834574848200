import PropTypes from 'prop-types';

import { formatDate, formatFrenchDate, getCompany } from '../../scripts/functions';

import './styles.scss';

const ConsultantHeaderInfos = ({ 
    currentConsultant,
    handleArrivalDateChange,
    handleCurrentLastnameChange,
    handleCurrentFirstnameChange,
    isMeetingConsultantPage
}) => {
    return (
        <div className="consultant-header-infos-container">
            {isMeetingConsultantPage
            && (
                <h1 className="infos-consultant-page-name-paragraph infos-consultant-page-lastname">
                    {currentConsultant.lastname}
                </h1>
            )}
            {!isMeetingConsultantPage
            && (
                <h1 className="infos-consultant-page-name infos-consultant-page-lastname">
                    <input type="text" value={currentConsultant.lastname} onChange={(event) => handleCurrentLastnameChange(event.target.value)} className="infos-consultant-input-name infos-consultant-input-lastname" name="lastname" />
                </h1>
            )}
            {isMeetingConsultantPage
            && (
                <h1 className="infos-consultant-page-name-paragraph infos-consultant-page-firstname-paragraph">
                    {currentConsultant.firstname}
                </h1>
            )}
            {!isMeetingConsultantPage
            && (
                    <h1 className="infos-consultant-page-name">
                    <input type="text" value={currentConsultant.firstname} onChange={(event) => handleCurrentFirstnameChange(event.target.value)} className="infos-consultant-input-name infos-consultant-input-firstname" name="firstname" />
                    </h1>
            )}
            <div className='infos-consultant-page-infos'>
                {isMeetingConsultantPage
                && (
                    <>
                        <p className='infos-consultant-page-info infos-consultant-page-status'>{currentConsultant.status}</p>
                        <p className='infos-consultant-page-info infos-consultant-page-factory'>{getCompany(+currentConsultant.company)}</p>
                    </>
                )}
                    {isMeetingConsultantPage
                    && (
                        <p className='infos-consultant-page-info infos-consultant-page-date infos-consultant-page-date-paragraph'>
                            Date d&apos;arrivée : le {formatFrenchDate(currentConsultant.arrival_date)}
                        </p>
                    )}
                    {!isMeetingConsultantPage
                    && (
                        <p className='infos-consultant-page-info infos-consultant-page-date'>
                            Date d&apos;arrivée : le <input type="date" value={formatDate(currentConsultant.arrival_date)} onChange={(event) => handleArrivalDateChange(event.target.value)} className="infos-consultant-input-date" name="arrival-date" />
                        </p>
                    )}
            </div>
        </div>
    );
};

ConsultantHeaderInfos.propTypes = {
    currentConsultant: PropTypes.shape({
        lastname: PropTypes.string,
        firstname: PropTypes.string,
        status: PropTypes.string,
        company: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
        arrival_date: PropTypes.string,
    }),
    handleArrivalDateChange: PropTypes.func.isRequired,
    handleCurrentLastnameChange: PropTypes.func.isRequired,
    handleCurrentFirstnameChange: PropTypes.func.isRequired,
    isMeetingConsultantPage: PropTypes.bool,
};

ConsultantHeaderInfos.defaultProps = {
    currentConsultant: {
        lastname: '',
        firstname: '',
        status: '',
        company: '',
        arrival_date: '',
    },
    isMeetingConsultantPage: false,
};

export default ConsultantHeaderInfos;