// === action types
export const CHECK_DONE_ACTION = 'CHECK_DONE_ACTION';
export const DELETE_ACTION_FROM_UNDONE_ACTIONS_LIST = 'DELETE_ACTION_FROM_UNDONE_ACTIONS_LIST';
export const FETCH_UNDONE_ACTIONS = 'FETCH_UNDONE_ACTIONS';
export const SET_IS_UNDONE_ACTIONS_LIST_LOADING = 'SET_IS_UNDONE_ACTIONS_LIST_LOADING';
export const SET_UNDONE_ACTIONS_LIST = 'SET_UNDONE_ACTIONS_LIST';

// === action creators
export const checkDoneAction = (actionId) => ({
  type: CHECK_DONE_ACTION,
  id: actionId,
});

export const deleteActionFromUndoneActionsList = (actionId) => ({
  type: DELETE_ACTION_FROM_UNDONE_ACTIONS_LIST,
  id: actionId,
});

export const fetchUndoneActions = () => ({
  type: FETCH_UNDONE_ACTIONS,
});

export const setIsUndoneActionsListLoading = (newValue) => ({
  type: SET_IS_UNDONE_ACTIONS_LIST_LOADING,
  value: newValue,
});

export const setUndoneActionsList = (actionsArray) => ({
  type: SET_UNDONE_ACTIONS_LIST,
  value: actionsArray,
});
