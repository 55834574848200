import axios from "axios";
import { read_cookie } from 'sfcookies';

import {
    CHECK_DONE_ACTION,
    // deleteActionFromUndoneActionsList,
    fetchUndoneActions,
    FETCH_UNDONE_ACTIONS,
    setIsUndoneActionsListLoading,
    setUndoneActionsList
} from "../actions/actionActions";

import { formatDate } from "../scripts/functions";

// const baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
// const baseUrl = 'http://localhost:8000/'

const actionMiddleware = (store) => (next) => (action) => {

    const currentEnv = store.getState().connexionReducer.appenv;
    let baseUrl = '';
    if (currentEnv === "prod") {
      baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
    } else {
      baseUrl = 'http://localhost:8000/'
    }

    switch (action.type) {
        case FETCH_UNDONE_ACTIONS:
            axios.get(
                // URL
                `${baseUrl}api/actions?column=is_done&value=0`,
                {
                  headers: {
                    Authorization: `Bearer ${read_cookie('jwt')}`,
                  },
                },
            )
                .then((response) => {
                    store.dispatch(setUndoneActionsList(response.data));
                })
                .then(() => {
                    store.dispatch(setIsUndoneActionsListLoading(false));
                });
            break;
        case CHECK_DONE_ACTION: {
            const actionId = action.id;

            const actionToPatch = {
                is_done: true,
                done_date: formatDate(Date().toLocaleString()),
            };

            axios.patch(
                // URL
                `${baseUrl}api/actions/edit/${actionId}`,
                actionToPatch,
                {
                  headers: {
                    Authorization: `Bearer ${read_cookie('jwt')}`,
                  },
                },
              )
                .then(() => {
                    store.dispatch(fetchUndoneActions());
                })
            break;
        }
        default:
    }

    // on passe l'action au suivant (middleware suivant ou reducer)
    next(action);
};

export default actionMiddleware;