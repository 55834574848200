import { connect } from 'react-redux';
import { checkDoneAction, fetchUndoneActions, setIsUndoneActionsListLoading } from '../../actions/actionActions';

// on importe le composant de présentation
import UndoneActionsTable from '../../components/UndoneActionsTable';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  undoneActionsArray: state.consultantReducer.undoneActionsArray,
  isUndoneActionsListLoading: state.consultantReducer.isUndoneActionsListLoading,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  checkDoneAction: (actionId) => {
    dispatch(checkDoneAction(actionId));
  },
  loadUndoneActions: () => {
    dispatch(fetchUndoneActions());
  },
  setIsUndoneActionsListLoading: (newValue) => {
    dispatch(setIsUndoneActionsListLoading(newValue));
  },
})
// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(UndoneActionsTable);