import { 
  // useEffect, 
  useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import GradeIcon from '@mui/icons-material/Grade';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import { formatFrenchDate } from '../../../scripts/functions';

import './styles.scss';
import Loader from '../../Loader';

const ActionsList = ({
  addNewAction,
  addActionToNewReport,
  currentActions,
  currentReportId,
  deleteAction,
  isNewReport,
  isActionsListLoading,
  newReportId,
  patchAction,
  previousActions,
  // consultantsInCharge,
  isConsultantsInChargeListLoading,
  // loadConsultantsInCharge,
}) => {
  
  // useEffect(() => {
  //   loadConsultantsInCharge();
  // }, []);
  
  // extract in an array firstnames of consultants who can be in charge of an action
  // const consultantsInChargeFirstname = consultantsInCharge.map((consultant) => (consultant.firstname));

  // convert 0/1 values to string to display
  const getIsDoneValue = (params) => {
    if (params.row.is_done == true) {
      return "Terminé"
    } else {
      return "En cours"
    }
  }

  // handle which column will be sorted at first
  const [sortModel, setSortModel] = useState([
    {
      field: 'is_done',
      sort: 'asc',
    },
  ]);

  const columns = useMemo(
    () => [
      {
        field: 'is_important',
        headerName: '',
        width: 10,
        align: 'center',
        renderCell: (params) => {
          if (params.value === false) {
            return <GradeOutlinedIcon 
              onClick={() => {
                if (isNewReport) {
                  patchAction(params.id, 'is_important', true, true);
                } else {
                  patchAction(params.id, 'is_important', true, false);
                }
              }}
            />
          }
          else {
            return <GradeIcon
              onClick={() => {
                if (isNewReport) {
                  patchAction(params.id, 'is_important', false, true); 
                } else {
                  patchAction(params.id, 'is_important', false, false); 
                }
              }} 
            />
          }
        },
      },
      {
        field: 'description',
        headerName: 'Action à faire',
        width: 380,
        flex: true,
        editable: true,
        renderCell: (params) => (
          <div className='test-textarea'>
            <p spellCheck="false">
              {params.value}
            </p>
          </div>
        ),
      },
      {
        field: 'person_in_charge',
        headerName: 'En charge',
        width: 100,
        type: 'singleSelect',
        valueOptions: [' ', 'Bertrand', 'Julien', 'Marie', 'Sophie', 'Manoha', 'Romain'],
        editable: true,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'creation_date',
        headerName: 'Créée le',
        width: 130,
        type: 'date',
        valueFormatter: (params) => {
          if (params.value) {
            return (formatFrenchDate(params.value))
          }
        },
        editable: true,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'is_done',
        headerName: 'Statut',
        width: 80,
        type: 'singleSelect',
        valueOptions: ['Terminé', 'En cours'],
        valueGetter: getIsDoneValue,
        // onchange on select : transform strings to 0/1 values
        valueParser: (value) => {
          if (value == 'Terminé') {
            return true
          } else {
            return false
          }
        },
        editable: true,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'done_date',
        headerName: 'Réalisée le',
        width: 130,
        type: 'date',
        valueFormatter: (params) => {
          if (params.value) {
            return (formatFrenchDate(params.value))
          }
        },
        editable: true,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'buttons',
        headerName: '',
        width: 20,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <DeleteTwoToneIcon
            onClick={() => {
              if (isNewReport) {
                deleteAction(params.id, true);
              } else {
                deleteAction(params.id, false);
              }
            }}
          />)
      }
    ],
    []
  );

  const handleEditRowsModelChange = (params) => {
    const actionId = +Object.keys(params);
    const propertyToUpdate = Object.keys(Object.values(params)[0])[0];
    const valueToUpdate = Object.values(Object.values(params)[0])[0].value;
    if (isNewReport) {
      patchAction(actionId, propertyToUpdate, valueToUpdate, true)
    } else {
      // setNewValueAction(params)
      patchAction(actionId, propertyToUpdate, valueToUpdate, false)
    }
  };

  return (
    <div className="actions-list-container" style={{ width: '100%' }}>
      {isConsultantsInChargeListLoading
      && (
        <Loader />
      )}
      {!isConsultantsInChargeListLoading
      && (
      <>
        <div className='report-container-subtitle-div'>
          <Button
            startIcon={<AddTwoToneIcon />}
            onClick={() => { (isNewReport) ? addNewAction(newReportId, true) : addNewAction(currentReportId, false); }}
            sx={{
              margin: "0 1em 0 0",
              padding: "0.5em",
              height: "30px",
              width: "220px",
              textTransform: "uppercase",
              fontFamily: "'Oswald', sans-serif",
              backgroundColor: "#999",
              color: "#FFF",
              borderRadius: "5px",
              border: "1px solid transparent",
            }}
          >
            Ajouter une nouvelle action
          </Button>
          {isNewReport
          && previousActions.length !==0
          && (
              <Button
                onClick={() => {
                  // initNewReportWithActions(previousActions)
                  previousActions.forEach((action) => {
                    addActionToNewReport(newReportId, action);
                  })
                }}
                sx={{
                  margin: "0",
                  padding: "0.5em",
                  height: "30px",
                  width: "220px",
                  textTransform: "uppercase",
                  fontFamily: "'Oswald', sans-serif",
                  backgroundColor: "#999",
                  color: "#FFF",
                  borderRadius: "5px",
                  border: "1px solid transparent",
                }}
              >
                Reporter les actions en cours
              </Button>
            )}
        </div>
        {!isActionsListLoading
        && (
          <DataGrid
            rows={currentActions}
            rowHeight={80}
            columns={columns}
            autoHeight
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            onEditRowsModelChange={(params) => { handleEditRowsModelChange(params) }}
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            rowsPerPageOptions={[10]}
            pageSize={10}
            sx={{ fontFamily: 'Quicksand', mt: 2 }}
            getCellClassName={(params) => {
              if (params.field === 'createdAt' || params.field === 'doneAt') {
                return 'actions-list-date-column'
              } else if (params.field === 'inCharge' || params.field === 'actionStatus') {
                return 'actions-list-dropdown-column'
              } else if (params.field === 'action') {
                return 'actions-list-action-column'
              } else { return 'actions-list-icon-column' }
            }}
          />
        )}
      </>
      )}
    </div>
  );
};

ActionsList.propTypes = {
  addActionToNewReport: PropTypes.func.isRequired,
  addNewAction: PropTypes.func.isRequired,
  currentActions: PropTypes.array,
  currentReportId: PropTypes.number,
  deleteAction: PropTypes.func.isRequired,
  isNewReport: PropTypes.bool,
  isActionsListLoading: PropTypes.bool.isRequired,
  newReportId: PropTypes.number,
  previousActions: PropTypes.array,
  patchAction: PropTypes.func.isRequired,
  consultantsInCharge: PropTypes.array.isRequired,
  isConsultantsInChargeListLoading: PropTypes.bool.isRequired,
  // loadConsultantsInCharge: PropTypes.func.isRequired,
};

ActionsList.defaultProps = {
  currentActions: [],
  currentReportId: 0,
  isNewReport: false,
  newReportId: 0,
  previousActions: [],
};

export default ActionsList;