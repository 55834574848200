import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import { formatDate } from '../../scripts/functions';

import ActionsList from '../../containers/Report/ActionsList';

import './styles.scss';
import { Button } from '@mui/material';
import Loader from '../Loader';

const Report = ({
    addClientToNewReport,
    addNewClient,
    currentWeek,
    currentReport,
    deleteClient,
    handleClientDateChange,
    handleClientNameChange,
    handleCommentChange,
    handleNewReportClientDateChange,
    handleNewReportClientNameChange,
    handleNewReportCommentChange,
    // initNewReportWithClients,
    isConsultantReportLoading,
    isInfosConsultantPage,
    isMeetingConsultantPage,
    isLastReport,
    isNewReport,
    lastReportWeekId,
    loadNewReport,
    loadReport,
    newReport,
    newReportWeekId,
    patchClient,
    patchComment,
    areNewReportsCreated,
    setIsCurrentReportClientReadOnly,
    setIsCurrentReportCommentReadOnly,
    setIsNewReportClientReadOnly,
    setIsNewReportCommentReadOnly,
    deleteReport,
    // setIsConsultantReportLoading,
}) => {
    {
        !isLastReport
        && !isNewReport
        && useEffect(() => {
            loadReport(currentWeek);
            return setIsAlertDivOpen(false);
        }, [currentWeek])
    }

    {
        isLastReport
        && useEffect(() => {
            loadReport(lastReportWeekId);
            // return setIsConsultantReportLoading(true);
        }, [])
    }

    {
        isNewReport
        && areNewReportsCreated
        && useEffect(() => {
            loadNewReport(newReportWeekId);
            // return setIsConsultantReportLoading(true);
        }, [])
    }

    const [clientReadOnly, setClientReadOnly] = useState(true);
    const [commentReadOnly, setCommentReadOnly] = useState(true);
    const [isAlertDivOpen, setIsAlertDivOpen] = useState(false);

    const handleClientReadOnlyFalse = () => {
        setClientReadOnly(false);
        {isNewReport
        && setIsNewReportClientReadOnly(false)}
        {!isNewReport
        && setIsCurrentReportClientReadOnly(false)}
    }

    const handleClientReadOnlyTrue = () => {
        {!isNewReport
        && currentReport.clients.forEach((client) => {
            patchClient(client.id, client.name, client.date)
        })}
        {isNewReport
        && newReport.clients.forEach((client) => {
            patchClient(client.id, client.name, client.date)
        })}

        {!isNewReport
        && setIsCurrentReportClientReadOnly(true)}
        {isNewReport
        && setIsNewReportClientReadOnly(true)}

        setClientReadOnly(true);
    }

    const handleCommentReadOnlyFalse = () => {
        setCommentReadOnly(false);
        {!isNewReport
        && setIsCurrentReportCommentReadOnly(false)}
        {isNewReport
        && setIsNewReportCommentReadOnly(false)}
    }

    const handleCommentReadOnlyTrue = () => {
        setCommentReadOnly(true);
        {!isNewReport
        && patchComment(currentReport.id, currentReport.comment)}
        {isNewReport
        && patchComment(newReport.id, newReport.comment)}

        {!isNewReport
        && setIsCurrentReportCommentReadOnly(true)}
        {isNewReport
        && setIsNewReportCommentReadOnly(true)}
    }

    return (
        <div className="report-container" style={{ backgroundColor: isNewReport ? "#FFF" : '#d5d5d5' }}>
            {isConsultantReportLoading
                && !isNewReport
                && (
                    <Loader />
                )}
            {(!isConsultantReportLoading
                || isNewReport)
                &&
                (
                    <>
                        <div className='report-container-subtitle-div'>
                            <h3 className='report-container-subtitle'>Bon de commande</h3>
                            {isMeetingConsultantPage
                            && (
                                <>
                                    {!isNewReport
                                        && <AddTwoToneIcon
                                            onClick={() => addNewClient(currentReport.id, false)}
                                        />}
                                    {isNewReport
                                        && <AddTwoToneIcon
                                            onClick={() => addNewClient(newReport.id, true)}
                                        />}
                                </>
                            )}
                            {isInfosConsultantPage
                            && (
                                <AddTwoToneIcon
                                    onClick={() => addNewClient(currentReport.id, false)}
                                />
                            )}
                            <ModeEditOutlineRoundedIcon
                                onClick={handleClientReadOnlyFalse}
                            />
                            <DoneRoundedIcon
                                onClick={handleClientReadOnlyTrue}
                            />
                            {isNewReport
                            && currentReport.clients.length !== 0
                            && (
                                <Button
                                    onClick={() => {
                                        // initNewReportWithClients(currentReport.clients)
                                        currentReport.clients.forEach((client) => {
                                            addClientToNewReport(newReport.id, client)
                                        })
                                    }}
                                    sx={{
                                    margin: "0 1em",
                                    padding: "0.5em",
                                    height: "30px",
                                    width: "220px",
                                    textTransform: "uppercase",
                                    fontFamily: "'Oswald', sans-serif",
                                    backgroundColor: "#999",
                                    color: "#FFF",
                                    borderRadius: "5px",
                                    border: "1px solid transparent",
                                    }}
                                >
                                    Reporter le bon de commande
                                </Button>
                            )}
                        </div>
                        {!isNewReport
                            && (
                                <>
                                    {currentReport.clients.length === 0
                                        && (
                                            <div key={1} className="report-container-item report-client">
                                                <p>Aucun client.</p>
                                            </div>
                                        )}
                                    {currentReport.clients.map((row) => (
                                        <div key={row.id} className="report-container-item report-client">
                                            <label htmlFor="client" className="report-client-label report-client-name-label">Client :</label>
                                            <input
                                                type="text"
                                                value={row.name}
                                                readOnly={clientReadOnly}
                                                style={{ 'border': clientReadOnly ? "" : "1px solid #999" }}
                                                spellCheck="false"
                                                onChange={(event) => handleClientNameChange(event.target.value, row.id)}
                                                id={row.id}
                                                className="report-client-input report-client-input-name"
                                                name="client"
                                            />
                                            <label htmlFor="date" className="report-client-label report-client-date-label">jusqu&apos;au</label>
                                            <input
                                                type="date"
                                                value={formatDate(row.date)}
                                                readOnly={clientReadOnly}
                                                style={{ 'border': clientReadOnly ? "" : "1px solid #999" }}
                                                spellCheck="false"
                                                onChange={(event) => handleClientDateChange(event.target.value, row.id)}
                                                id={row.id}
                                                className="report-client-input report-client-input-date"
                                                name="date"
                                            />
                                            <DeleteTwoToneIcon onClick={() => {deleteClient(row.id, false)}} />
                                        </div>
                                    ))}
                                </>
                            )
                        }
                        {isNewReport
                            && (
                                <>
                                    {newReport.clients.length === 0
                                        && (
                                            <div key={1} className="report-container-item report-client">
                                                <p>Aucun client.</p>
                                            </div>
                                        )}
                                    {newReport.clients.map((row) => (
                                        <div key={row.id} className="report-container-item report-client">
                                            <label htmlFor="client" className="report-client-label report-client-name-label">Client :</label>
                                            <input
                                                type="text"
                                                value={row.name}
                                                readOnly={clientReadOnly}
                                                style={{ 'border': clientReadOnly ? "" : "1px solid #999" }}
                                                spellCheck="false"
                                                onChange={(event) => handleNewReportClientNameChange(event.target.value, row.id)}
                                                id={row.id}
                                                className="report-client-input report-client-input-name"
                                                name="client"
                                            />
                                            <label htmlFor="date" className="report-client-label report-client-date-label">jusqu&apos;au</label>
                                            <input
                                                type="date"
                                                value={formatDate(row.date)}
                                                readOnly={clientReadOnly}
                                                style={{ 'border': clientReadOnly ? "" : "1px solid #999" }}
                                                spellCheck="false"
                                                onChange={(event) => handleNewReportClientDateChange(event.target.value, row.id)}
                                                id={row.id}
                                                className="report-client-input report-client-input-date"
                                                name="date"
                                            />
                                            <DeleteTwoToneIcon onClick={() => {deleteClient(row.id, true)}} />
                                        </div>
                                    ))}
                                </>
                            )
                        }
                        <div className='report-container-subtitle-div'>
                            <h3 className='report-container-subtitle'>Commentaire</h3>
                            <ModeEditOutlineRoundedIcon onClick={handleCommentReadOnlyFalse} />
                            <DoneRoundedIcon onClick={handleCommentReadOnlyTrue} />
                            {isNewReport
                            && currentReport.comment
                            && (
                                <Button
                                    onClick={() => {
                                        // initNewReportWithClients(currentReport.clients)
                                        handleNewReportCommentChange(currentReport.comment);
                                        patchComment(newReport.id, currentReport.comment);
                                    }}
                                    sx={{
                                    margin: "0 1em",
                                    padding: "0.5em",
                                    height: "30px",
                                    width: "200px",
                                    textTransform: "uppercase",
                                    fontFamily: "'Oswald', sans-serif",
                                    backgroundColor: "#999",
                                    color: "#FFF",
                                    borderRadius: "5px",
                                    border: "1px solid transparent",
                                    }}
                                >
                                    Reporter le commentaire
                                </Button>
                            )}
                        </div>
                        {!isNewReport
                            && (
                                <>
                                    <div className="report-container-item report-comment">
                                        <textarea
                                            spellCheck="false"
                                            type="text"
                                            value={currentReport.comment}
                                            readOnly={commentReadOnly}
                                            style={{ 'border': commentReadOnly ? "" : "1px solid #999" }}
                                            onChange={(event) => handleCommentChange(event.target.value)}
                                            className="report-comment-input report-comment-input-name"
                                            name="comment"
                                        />
                                    </div>
                                </>
                            )
                        }
                        {isNewReport
                            && (
                                <>
                                    <div className="report-container-item report-comment">
                                        <textarea
                                            spellCheck="false"
                                            type="text"
                                            value={newReport.comment}
                                            readOnly={commentReadOnly}
                                            style={{ 'border': commentReadOnly ? "" : "1px solid #999" }}
                                            onChange={(event) => handleNewReportCommentChange(event.target.value)}
                                            className="report-comment-input report-comment-input-name"
                                            name="comment"
                                        />
                                    </div>
                                </>
                            )
                        }
                        <div className='report-container-subtitle-div'>
                            <h3 className='report-container-subtitle report-container-subtitle-actions'>Liste des actions</h3>
                        </div>
                        <div className="report-container-item report-actons-list">
                            {!isNewReport
                                && <ActionsList currentActions={currentReport.actions} currentReportId={currentReport.id} />}
                            {isNewReport
                                && <ActionsList currentActions={newReport.actions} newReportId={newReport.id} previousActions={currentReport.actions.filter((action) => action.is_done == false)} isNewReport />}
                        </div>
                        {isInfosConsultantPage
                            && (
                                <Button
                                    onClick={() => setIsAlertDivOpen(true)}
                                    sx={{
                                    margin: "0 1em",
                                    padding: "0.5em",
                                    height: "30px",
                                    width: "220px",
                                    textTransform: "uppercase",
                                    fontFamily: "'Oswald', sans-serif",
                                    backgroundColor: "#999",
                                    color: "#FFF",
                                    borderRadius: "5px",
                                    border: "1px solid transparent",
                                    }}
                                >
                                    Supprimer le compte-rendu
                                </Button>
                            )}
                            {isAlertDivOpen
                    && (
                        <div className='meeting-consultant-page-modal-error'>
                            <p style={{ fontWeight: 'bold' }}>Etes-vous sûr de vouloir supprimer le compte-rendu ?</p>
                            <button
                                onClick={() => {deleteReport(currentReport.id)}}
                            >
                                Oui
                            </button>
                            <button
                                onClick={() => {setIsAlertDivOpen(false);}}
                            >
                                Annuler
                            </button>
                        </div>
                    )}
                    </>
                )}
        </div>
    );
};

Report.propTypes = {
    addClientToNewReport: PropTypes.func.isRequired,
    addNewClient: PropTypes.func.isRequired,
    areNewReportsCreated: PropTypes.bool.isRequired,
    currentConsultant: PropTypes.object.isRequired,
    currentReport: PropTypes.shape({
        id: PropTypes.number,
        actions: PropTypes.array,
        clients: PropTypes.array,
        comment: PropTypes.string,
    }).isRequired,
    currentWeek: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    deleteClient: PropTypes.func.isRequired,
    handleClientDateChange: PropTypes.func.isRequired,
    handleClientNameChange: PropTypes.func.isRequired,
    handleCommentChange: PropTypes.func.isRequired,
    handleNewReportClientDateChange: PropTypes.func.isRequired,
    handleNewReportClientNameChange: PropTypes.func.isRequired,
    handleNewReportCommentChange: PropTypes.func.isRequired,
    // initNewReportWithClients: PropTypes.func.isRequired,
    isConsultantReportLoading: PropTypes.bool.isRequired,
    isInfosConsultantPage: PropTypes.bool,
    isMeetingConsultantPage: PropTypes.bool,
    isLastReport: PropTypes.bool,
    isNewReport: PropTypes.bool,
    lastReportWeekId: PropTypes.number,
    loadNewReport: PropTypes.func.isRequired,
    loadReport: PropTypes.func.isRequired,
    newReport: PropTypes.shape({
        id: PropTypes.number,
        actions: PropTypes.array,
        clients: PropTypes.array,
        comment: PropTypes.string,
    }).isRequired,
    newReportWeekId: PropTypes.number,
    patchClient: PropTypes.func.isRequired,
    patchComment: PropTypes.func.isRequired,
    setIsConsultantReportLoading: PropTypes.func.isRequired,
    setIsCurrentReportClientReadOnly: PropTypes.func.isRequired,
    setIsCurrentReportCommentReadOnly: PropTypes.func.isRequired,
    setIsNewReportClientReadOnly: PropTypes.func.isRequired,
    setIsNewReportCommentReadOnly: PropTypes.func.isRequired,
    deleteReport: PropTypes.func.isRequired,
};

Report.defaultProps = {
    currentReport: {
        id: 0,
        clients: [
            {
                id: 1,
                name: '',
                date: "2022-01-01",
            }
        ],
        comment: '',
        actions: [],
    },
    currentWeek: 0,
    isInfosConsultantPage: false,
    isMeetingConsultantPage: false,
    isLastReport: false,
    isNewReport: false,
    lastReportWeekId: 0,
    // newReportWeekId: 0,
};

export default Report;