import { Link } from 'react-router-dom';

import './styles.scss';

const Footer = () => (
    <div className="footer-container">
        <p>© HCS Groupe - Tous droits réservés -
        <Link to="/mentions-legales" className='legal-mentions-link'>
             Mentions Légales
        </Link>
        </p>
    </div>
);

export default Footer;