import { connect } from 'react-redux';
import { setNewDoesAction, setNewStatus, setNewCompany } from '../../../actions/consultantActions';

// on importe le composant de présentation
import ConsultantFormInfos from '../../../components/InfosConsultantForm/ConsultantFormInfos';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  newConsultant: state.consultantReducer.newConsultant,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  handleNewDoesActionChange: (value) => {
    dispatch(setNewDoesAction(value));
  },
  handleNewStatusChange: (value) => {
    dispatch(setNewStatus(value));
  },
  handleNewCompanyChange: (value) => {
    dispatch(setNewCompany(value));
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantFormInfos);