import { useEffect } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import './styles.scss';
import { getColumnForRequest } from '../../scripts/functions';
import Loader from '../Loader';

const DocumentsToCheck = ({
    currentConsultant,
    crealisDocumentsList,
    crealisPortsideDocumentsList,
    portsideDocumentsList,
    hcsDocumentsList,
    isDocumentsListLoading,
    loadDocumentsList,
    checkDocument,
    uncheckDocument
}) => {
    const columnForRequest = getColumnForRequest(+currentConsultant.company);

    useEffect(() => {
        loadDocumentsList(columnForRequest, +currentConsultant.company);
    }, [currentConsultant.checkedDocuments]);

    let documentsList = [];

    switch (+currentConsultant.company) {
        case 1:
            documentsList = [...crealisDocumentsList]
            break;
        case 2:
            documentsList = [...crealisPortsideDocumentsList]
            break;
        case 3:
            documentsList = [...portsideDocumentsList]
            break;
        case 4:
            documentsList = [...hcsDocumentsList]
            break;
        default:
    }

    return (
        <div className="documents-to-check-container">
            {isDocumentsListLoading
            && (
                <Loader />
            )}
            {!isDocumentsListLoading
            && (
                <>
                    <FormControl component="fieldset" variant="standard" fullWidth>
                        <FormGroup row sx={{ justifyContent: 'center'}}>
                        {/* looping on documentslist for this company
                        we display each document as a checkbox */}
                        {documentsList.map((documentItem) => {
                            // init a variable that will change in the foreach
                            let isDocumentChecked = false;
                            let currentCheckingDocumentId = 0;
                            // looping on consultant's checkeddocuments
                            currentConsultant.checkedDocuments.forEach((checkedDocument) => {
                                // for each checkdocument, if it fits with a document from the documentslist
                                if (checkedDocument.document.id === documentItem.id) {
                                    // its checkbox will be checked
                                    isDocumentChecked = true;
                                    // and we save its id to handle this change
                                    currentCheckingDocumentId = checkedDocument.id;
                                }
                            })
                            return (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isDocumentChecked}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                checkDocument(event.target.value, currentConsultant.id);
                                            } else {
                                                uncheckDocument(currentCheckingDocumentId);
                                            }
                                        }}
                                        name={documentItem.name}
                                        value={documentItem.id}
                                        sx={{ mx: 2, textAlign: "center" }}
                                    />
                                }
                                label={
                                    <p style={{ margin: '1em', width: '100%', textAlign: "center"  }}>
                                        {documentItem.name}
                                    </p>
                                }
                                sx={{ mx: 2, width: '20%', textAlign: "center",  }}
                                key={documentItem.id}
                                disableTypography
                            />
                            )
                        })}
                        </FormGroup>
                    </FormControl>
                </>
            )}
        </div>
    );
}

DocumentsToCheck.propTypes = {
    currentConsultant: PropTypes.shape({
        id: PropTypes.number,
        company: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
        checkedDocuments: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                is_checked: PropTypes.bool,
            }),
        ),
    }).isRequired,
    crealisDocumentsList: PropTypes.array,
    crealisPortsideDocumentsList: PropTypes.array,
    portsideDocumentsList: PropTypes.array,
    hcsDocumentsList: PropTypes.array,
    loadDocumentsList: PropTypes.func.isRequired,
    checkDocument: PropTypes.func.isRequired,
    uncheckDocument: PropTypes.func.isRequired,
    isDocumentsListLoading: PropTypes.bool.isRequired,
};

DocumentsToCheck.defaultProps = {
    currentConsultant: {
        id: 0,
        company: '',
        checkedDocuments: [
            {
                id: 0,
                is_checked: false,
            }
        ],
    },
    documentsList: [],
};

export default DocumentsToCheck;