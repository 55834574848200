export function descendingComparator(a, b, orderBy) {
    // we need to precise what to do when we want to sort by lastname because lastname property is not directly on action table
    if (orderBy === "lastname") {
        if (b.report.consultant.lastname < a.report.consultant.lastname) {
            return -1;
        }
        if (b.report.consultant.lastname > a.report.consultant.lastname) {
            return 1;
        }
        return 0;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
