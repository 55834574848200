import axios from "axios";
import { read_cookie } from 'sfcookies';

import { saveConsultant } from "../actions/consultantActions";
import {
    FETCH_ACTIVE_CONSULTANTS,
    FETCH_MEETING_CONSULTANT,
    filterMeetingConsultantsList,
    POST_WEEK,
    saveActiveConsultantsArray,
    saveCurrentMeetingWeek,
    setIsMeetingConsultantPageLoading,
    setIsMeetingConsultantsListLoading
    // saveCurrentMeetingConsultant,
} from "../actions/meetingActions";

// const baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
// const baseUrl = 'http://localhost:8000/'

const meetingMiddleware = (store) => (next) => (action) => {

    const currentEnv = store.getState().connexionReducer.appenv;
    let baseUrl = '';
    if (currentEnv === "prod") {
      baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
    } else {
      baseUrl = 'http://localhost:8000/'
    }

    switch (action.type) {
        case POST_WEEK: {
            const weekToPost = {
                number: +store.getState().meetingReducer.inputWeek.weekNumber,
                start_date: store.getState().meetingReducer.inputWeek.startDate,
                end_date: store.getState().meetingReducer.inputWeek.endDate,
            };
            axios.post(
                // URL
                `${baseUrl}api/weeks/add`,
                weekToPost,
                {
                  headers: {
                    Authorization: `Bearer ${read_cookie('jwt')}`,
                  },
                },
            )
                .then((response) => {
                    store.dispatch(saveCurrentMeetingWeek(response.data));
                });
            break;
        }
        case FETCH_ACTIVE_CONSULTANTS:
            axios.get(
                // URL
                `${baseUrl}api/consultants?column=is_active&value=1`,
                {
                  headers: {
                    Authorization: `Bearer ${read_cookie('jwt')}`,
                  },
                },
            )
                .then((response) => {
                    store.dispatch(saveActiveConsultantsArray(response.data));
                })
                .then(() => {
                    store.dispatch(filterMeetingConsultantsList(action.filterArray)); 
                    setIsMeetingConsultantsListLoading(false);
                });
            break;
        case FETCH_MEETING_CONSULTANT: {
            const meetingConsultantsArray = [...store.getState().meetingReducer.meetingConsultantsArray];
            let consultantId = 0;
            meetingConsultantsArray.forEach((consultant) => {
                if (consultant.consultant_meeting_id == action.value) {
                    consultantId = consultant.id;
                }
            })
            axios.get(
                // URL
                `${baseUrl}api/consultants/${consultantId}`,
                {
                  headers: {
                    Authorization: `Bearer ${read_cookie('jwt')}`,
                  },
                },
            )
                .then((response) => {
                    store.dispatch(saveConsultant(response.data));
                })
                .then(() => {
                    store.dispatch(setIsMeetingConsultantPageLoading(false));
                });
            break;
        }
        default:
    }

    // on passe l'action au suivant (middleware suivant ou reducer)
    next(action);
};

export default meetingMiddleware;