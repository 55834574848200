import { connect } from 'react-redux';
import {
  fetchConsultant,
  patchConsultant,
  setIsActive,
  setIsConsultantPageLoading,
  fetchConsultantsInCharge
} from '../../actions/consultantActions';
import {
  setIsCurrentReportClientReadOnly,
  setIsCurrentReportCommentReadOnly,
  setIsNewReportClientReadOnly,
  setIsNewReportCommentReadOnly
} from '../../actions/reportActions';

// on importe le composant de présentation
import InfosConsultantPage from '../../components/InfosConsultantPage';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  isConsultantPageLoading: state.consultantReducer.isConsultantPageLoading,
  currentConsultant: state.consultantReducer.currentConsultant,
  isCurrentReportClientReadOnly: state.consultantReducer.isCurrentReportClientReadOnly,
  isCurrentReportCommentReadOnly: state.consultantReducer.isCurrentReportCommentReadOnly,
  isNewReportClientReadOnly: state.consultantReducer.isNewReportClientReadOnly,
  isNewReportCommentReadOnly: state.consultantReducer.isNewReportCommentReadOnly,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  loadConsultant: (value) => {
    dispatch(fetchConsultant(value));
  },
  patchConsultant: () => {
    dispatch(patchConsultant());
  },
  setIsConsultantPageLoading: (newValue) => {
    dispatch(setIsConsultantPageLoading(newValue));
  },
  setIsActive: (newValue) => {
    dispatch(setIsActive(newValue));
  },
  loadConsultantsInCharge: () => {
    dispatch(fetchConsultantsInCharge());
  },
  setIsCurrentReportClientReadOnly: (newValue) => {
    dispatch(setIsCurrentReportClientReadOnly(newValue));
  },
  setIsCurrentReportCommentReadOnly: (newValue) => {
    dispatch(setIsCurrentReportCommentReadOnly(newValue));
  },
  setIsNewReportClientReadOnly: (newValue) => {
    dispatch(setIsNewReportClientReadOnly(newValue));
  },
  setIsNewReportCommentReadOnly: (newValue) => {
    dispatch(setIsNewReportCommentReadOnly(newValue));
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(InfosConsultantPage);