import { NavLink } from 'react-router-dom';
// import PropTypes from 'prop-types';

import './styles.scss';

const NavBar = () => (
    <div className="navbar-container">
        <ul className='navbar-list'>
            <li>
                <NavLink
                    className={({ isActive }) => (isActive ? 'navbar-list-current-item navbar-list-item' : 'navbar-list-item')}
                    to="/consultants"
                >
                    Liste des consultants
                </NavLink>
            </li>
            <li>
                <NavLink
                    className={({ isActive }) => (isActive ? 'navbar-list-current-item navbar-list-item' : 'navbar-list-item')}
                    to="/reunion"
                >
                    Démarrer une réunion
                </NavLink> 
            </li>
            {/* <li>
                <NavLink
                    className={({ isActive }) => (isActive ? 'navbar-list-current-item navbar-list-item' : 'navbar-list-item')}
                    to="/comptes-rendus"
                >
                    Générer un document
                </NavLink>           
            </li> */}
        </ul>
    </div>
);

// NavBar.propTypes = {
// };

export default NavBar;