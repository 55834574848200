import { connect } from 'react-redux';
import { postNewConsultant, resetNewConsultant } from '../../actions/consultantActions';

// on importe le composant de présentation
import InfosConsultantForm from '../../components/InfosConsultantForm';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  newConsultant: state.consultantReducer.newConsultant,
  hasNewConsultantBeenCreated: state.consultantReducer.hasNewConsultantBeenCreated,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  postNewConsultant: () => {
    dispatch(postNewConsultant());
  },
  resetNewConsultant: () => {
    dispatch(resetNewConsultant());
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(InfosConsultantForm);