import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import MeetingListTableHead from './MeetingListTableHead';

import { getComparator } from './table-functions';

import './styles.scss'
import { getCompany } from '../../../../scripts/functions';
import Loader from '../../../Loader';

const MeetingListTable = ({
  consultantsList,
  isMeetingConsultantsListLoading,
  setIsMeetingConsultantPageLoading,
  setIsDocumentsListLoading,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('company');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - consultantsList.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      {isMeetingConsultantsListLoading
        && (
          <Loader />
        )}
      {!isMeetingConsultantsListLoading
        && (
          <>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={'small'}
                >
                  <MeetingListTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={consultantsList.length}
                  />
                  <TableBody>
                    {consultantsList.slice().sort(getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((consultant, index) => {
                        const labelId = `meeting-list-table-row-${index}`;

                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="normal"
                            >
                              <p className="meeting-list-table-cell-content">
                                <Link
                                  to={`/reunion/${consultant.consultant_meeting_id}`}
                                  onClick={() => {
                                    setIsMeetingConsultantPageLoading(true);
                                    setIsDocumentsListLoading(true);
                                  }}
                                >
                                {consultant.lastname.toUpperCase()} {consultant.firstname}
                                </Link>
                              </p>
                            </TableCell>
                            <TableCell align="left"><p className="meeting-list-table-cell-content">{consultant.status}</p></TableCell>
                            <TableCell align="left"><p className="meeting-list-table-cell-content">{getCompany(+consultant.company)}</p></TableCell>
                            <TableCell align="left">
                              {consultant.has_report_been_modified === false
                                &&
                                (
                                  <p className="meeting-list-table-cell-content">
                                  <Link to={`/reunion/${consultant.consultant_meeting_id}`}>
                                    En cours
                                  </Link>
                                </p>
                                )
                              }
                              {consultant.has_report_been_modified === true
                                &&
                                (
                                  <p className="meeting-list-table-cell-content">
                                  <Link to={`/reunion/${consultant.consultant_meeting_id}`}>
                                    Terminé
                                  </Link>
                                </p>
                                )
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (33) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={consultantsList.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="Consultant par page :"
                labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} sur ${count !== -1 ? count : `more than ${to}`}`; }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}
    </Box>
  );
}

MeetingListTable.propTypes = {
  isMeetingConsultantsListLoading: PropTypes.bool.isRequired,
  consultantsList: PropTypes.array.isRequired,
  setIsMeetingConsultantPageLoading: PropTypes.func.isRequired,
  setIsDocumentsListLoading: PropTypes.func.isRequired,
};

export default MeetingListTable;