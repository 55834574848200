import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import axios from 'axios';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Typography } from '@mui/material';

import UndoneActionsTableHead from './UndoneActionsTableHead';

import { getComparator } from './table-functions';
import { formatFrenchDate } from '../../scripts/functions';

import './styles.scss'
import Loader from '../Loader';

const UndoneActionsTable = ({
    undoneActionsArray,
    loadUndoneActions,
    isUndoneActionsListLoading,
    setIsUndoneActionsListLoading,
    checkDoneAction
}) => {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('creation_date');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - undoneActionsArray.length) : 0;


    useEffect(() => {
        loadUndoneActions();
        return () => {
            setIsUndoneActionsListLoading(true);
        }
    },
        []);

    return (
        <>
            {isUndoneActionsListLoading
                && (
                    <Loader />
                )}
                {!isUndoneActionsListLoading
                    && undoneActionsArray.length === 0
                    && (
                        <Typography
                            sx={{ pt: 2 }}
                            align="center"
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Aucune action en cours
                        </Typography>
                    )}
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    {!isUndoneActionsListLoading
                        && undoneActionsArray.length !== 0
                        && (
                            <>
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={'small'}
                                    >
                                        <UndoneActionsTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={undoneActionsArray.length}
                                        />
                                        <TableBody>
                                            {undoneActionsArray.slice().sort(getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    const labelId = `undone-actions-table-row-${index}`;
                                                    const newDate = formatFrenchDate(row.creation_date);

                                                    return (
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={index}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                                padding="normal"
                                                            >
                                                                <p className="undone-actions-table-cell-content">
                                                                    <Link to="/consultants/1">
                                                                        {newDate}
                                                                    </Link>
                                                                </p>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <p className="undone-actions-table-cell-content">
                                                                    {row.description}
                                                                </p>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <p className="undone-actions-table-cell-content">
                                                                    {row.person_in_charge}
                                                                </p>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <p className="undone-actions-table-cell-content">
                                                                    {row.report.consultant.lastname.toUpperCase()} {row.report.consultant.firstname}
                                                                </p>
                                                            </TableCell>
                                                            <TableCell align="left" sx={{ width: 100 }}>
                                                                <DoneRoundedIcon
                                                                    onClick={() => {
                                                                        checkDoneAction(row.id)
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: (33) * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={undoneActionsArray.length}
                                    rowsPerPage={rowsPerPage}
                                    labelRowsPerPage="Actions par page :"
                                    labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} sur ${count !== -1 ? count : `more than ${to}`}`; }}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </>
                        )}
                </Paper>
            </Box>
        </>
            );
};

            UndoneActionsTable.propTypes = {
                checkDoneAction: PropTypes.func.isRequired,
            undoneActionsArray: PropTypes.array.isRequired,
            loadUndoneActions: PropTypes.func.isRequired,
            isUndoneActionsListLoading: PropTypes.bool.isRequired,
            setIsUndoneActionsListLoading: PropTypes.func.isRequired,
};

            export default UndoneActionsTable;