import { connect } from 'react-redux';
import {
  setEndDate,
  setStartDate,
  setWeekNumber,
  postWeek,
  displayMeetingConsultantsList,
  setAreNewReportsCreated,
  setHasMeetingStarted,
  fetchMeetingConsultant,
  setIsMeetingConsultantPageLoading
} from '../../actions/meetingActions';
import { postNewReport } from '../../actions/reportActions';

// on importe le composant de présentation
import MeetingPage from '../../components/MeetingPage';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  weekNumber: state.meetingReducer.inputWeek.weekNumber,
  startDate: state.meetingReducer.inputWeek.startDate,
  endDate: state.meetingReducer.inputWeek.endDate,
  meetingConsultantsArray: state.meetingReducer.meetingConsultantsArray,
  isMeetingConsultantsListLoading: state.meetingReducer.isMeetingConsultantsListLoading,
  isMeetingConsultantsListDisplayed: state.meetingReducer.isMeetingConsultantsListDisplayed,
  areNewReportsCreated: state.meetingReducer.areNewReportsCreated,
  lastValidateReportConsultantId: state.meetingReducer.lastValidateReportConsultantId,
  hasMeetingStarted: state.meetingReducer.hasMeetingStarted,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  handleWeekNumberChange: (newWeekNumber) => {
    dispatch(setWeekNumber(newWeekNumber))
  },
  handleStartDateChange: (newStartDate) => {
    dispatch(setStartDate(newStartDate))
  },
  handleEndDateChange: (newEndDate) => {
    dispatch(setEndDate(newEndDate))
  },
  postWeek: () => {
    dispatch(postWeek())
  },
  createNewReport: (consultantId) => {
    dispatch(postNewReport(consultantId))
  },
  displayMeetingConsultantsList: (newValue) => {
    dispatch(displayMeetingConsultantsList(newValue))
  },
  setAreNewReportsCreated: (newValue) => {
    dispatch(setAreNewReportsCreated(newValue))
  },
  setHasMeetingStarted: (newValue) => {
    dispatch(setHasMeetingStarted(newValue))
  },
  loadMeetingConsultant: (meetingConsultantId) => {
    dispatch(fetchMeetingConsultant(meetingConsultantId));
  },
  setIsMeetingConsultantPageLoading: (newValue) => {
    dispatch(setIsMeetingConsultantPageLoading(newValue))
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(MeetingPage);