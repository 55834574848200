import { Link } from 'react-router-dom';
import './styles.scss';

const UnauthorizedPage = () => (
    <div className="unauthorized-page-container">
        <h2>Vous devez être connecté pour accéder à cette page.</h2>
        <Link to="/">
            <button className="unauthorized-page-home-button">
                Retour à la page de connexion
            </button>
        </Link>
    </div>
);

export default UnauthorizedPage;