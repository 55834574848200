// === action types
export const DISPLAY_MEETING_CONSULTANTS_LIST = 'DISPLAY_MEETING_CONSULTANTS_LIST';
export const FILTER_MEETING_CONSULTANTS_LIST = 'FILTER_MEETING_CONSULTANTS_LIST';
export const SET_ARE_NEW_REPORTS_CREATED = 'SET_ARE_NEW_REPORTS_CREATED';
export const SET_WEEK_NUMBER = 'SET_WEEK_NUMBER';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_IS_MEETING_CONSULTANT_PAGE_LOADING = 'SET_IS_MEETING_CONSULTANT_PAGE_LOADING';
export const SET_IS_MEETING_CONSULTANTS_LIST_LOADING = 'SET_IS_MEETING_CONSULTANTS_LIST_LOADING';
export const FETCH_ACTIVE_CONSULTANTS = 'FETCH_ACTIVE_CONSULTANTS';
export const VALIDATE_REPORT_STATUS = 'VALIDATE_REPORT_STATUS';
export const FETCH_MEETING_CONSULTANT = 'FETCH_MEETING_CONSULTANT';
export const POST_WEEK = 'POST_WEEK';
export const SAVE_CURRENT_MEETING_WEEK = 'SAVE_CURRENT_MEETING_WEEK';
export const SAVE_ACTIVE_CONSULTANTS_ARRAY = 'SAVE_ACTIVE_CONSULTANTS_ARRAY';
export const SAVE_CURRENT_MEETING_CONSULTANT = 'SAVE_CURRENT_MEETING_CONSULTANT';
export const SET_HAS_MEETING_STARTED = 'SET_HAS_MEETING_STARTED';
export const SET_IS_COMPANY_CHECKED = 'SET_IS_COMPANY_CHECKED';
export const SET_FILTER_VALUES_ARRAY = 'SET_FILTER_VALUES_ARRAY';

// === action creators
export const displayMeetingConsultantsList = (newValue) => ({
  type: DISPLAY_MEETING_CONSULTANTS_LIST,
  value: newValue,
});

export const filterMeetingConsultantsList = (companyValuesArray) => ({
  type: FILTER_MEETING_CONSULTANTS_LIST,
  values: companyValuesArray,
});

export const setAreNewReportsCreated = (newValue) => ({
  type: SET_ARE_NEW_REPORTS_CREATED,
  value: newValue,
});

export const setWeekNumber = (newWeekNumber) => ({
  type: SET_WEEK_NUMBER,
  value: newWeekNumber,
});

export const setStartDate = (newStartDate) => ({
  type: SET_START_DATE,
  value: newStartDate,
});

export const setEndDate = (newEndDate) => ({
  type: SET_END_DATE,
  value: newEndDate,
});

export const setIsMeetingConsultantPageLoading = (newValue) => ({
  type: SET_IS_MEETING_CONSULTANT_PAGE_LOADING,
  value: newValue,
});

export const setIsMeetingConsultantsListLoading = (newValue) => ({
  type: SET_IS_MEETING_CONSULTANTS_LIST_LOADING,
  value: newValue,
});

export const postWeek = () => ({
  type: POST_WEEK,
});

export const saveCurrentMeetingWeek = (postedWeek) => ({
  type: SAVE_CURRENT_MEETING_WEEK,
    value: postedWeek,
  });
  
  export const saveActiveConsultantsArray = (activeConsultantsArray) => ({
    type: SAVE_ACTIVE_CONSULTANTS_ARRAY,
    value: activeConsultantsArray,
  });

export const saveCurrentMeetingConsultant = (meetingConsultant) => ({
    type: SAVE_CURRENT_MEETING_CONSULTANT,
    value: meetingConsultant,
  });
  
  export const fetchActiveConsultants = (filterValuesArray) => ({
    type: FETCH_ACTIVE_CONSULTANTS,
    filterArray: filterValuesArray
  });

  export const fetchMeetingConsultant = (meetingConsultantId) => ({
    type: FETCH_MEETING_CONSULTANT,
    value: meetingConsultantId,
  });
  
  export const validateReportStatus = (meetingConsultantId) => ({
    type: VALIDATE_REPORT_STATUS,
    id: meetingConsultantId,
  });

  export const setHasMeetingStarted = (newValue) => ({
    type: SET_HAS_MEETING_STARTED,
    value: newValue,
  });

  export const setIsCompanyChecked = (companyName, isChecked) => ({
    type: SET_IS_COMPANY_CHECKED,
    company: companyName,
    value: isChecked,
  });

  export const setFilterValuesArray = (filterValue) => ({
    type: SET_FILTER_VALUES_ARRAY,
    value: filterValue,
  });