// === action types
export const FETCH_DOCUMENTS_LIST = 'FETCH_DOCUMENTS_LIST';
export const SAVE_DOCUMENTS_LIST = 'SAVE_DOCUMENTS_LIST';
export const SET_IS_DOCUMENTS_LIST_LOADING = 'SET_IS_DOCUMENTS_LIST_LOADING';
export const CHECK_DOCUMENT = 'CHECK_DOCUMENT';
export const SET_CHECKED_DOCUMENT = 'SET_CHECKED_DOCUMENT';
export const SET_UNCHECKED_DOCUMENT = 'SET_UNCHECKED_DOCUMENT';
export const UNCHECK_DOCUMENT = 'UNCHECK_DOCUMENT';
export const SAVE_CREALIS_DOCUMENTS_ARRAY = 'SAVE_CREALIS_DOCUMENTS_ARRAY';
export const SAVE_CREALIS_PORTSIDE_DOCUMENTS_ARRAY = 'SAVE_CREALIS_PORTSIDE_DOCUMENTS_ARRAY';
export const SAVE_PORTSIDE_DOCUMENTS_ARRAY = 'SAVE_PORTSIDE_DOCUMENTS_ARRAY';
export const SAVE_HCS_DOCUMENTS_ARRAY = 'SAVE_HCS_DOCUMENTS_ARRAY';

// === action creators
export const fetchDocumentsList = (selectedCompany, selectedCompanyValue) => ({
  type: FETCH_DOCUMENTS_LIST,
  company: selectedCompany,
  companyValue: selectedCompanyValue,
});

export const saveCrealisDocumentsArray = (crealisDocumentsArray) => ({
  type: SAVE_CREALIS_DOCUMENTS_ARRAY,
  value: crealisDocumentsArray,
});

export const saveCrealisPortsideDocumentsArray = (crealisPortsideDocumentsArray) => ({
  type: SAVE_CREALIS_PORTSIDE_DOCUMENTS_ARRAY,
  value: crealisPortsideDocumentsArray,
});

export const savePortsideDocumentsArray = (portsideDocumentsArray) => ({
  type: SAVE_PORTSIDE_DOCUMENTS_ARRAY,
  value: portsideDocumentsArray,
});

export const saveHCSDocumentsArray = (hcsDocumentsArray) => ({
  type: SAVE_HCS_DOCUMENTS_ARRAY,
  value: hcsDocumentsArray,
});

export const setIsDocumentsListLoading = (newValue) => ({
  type: SET_IS_DOCUMENTS_LIST_LOADING,
  value: newValue,
});

export const checkDocument = (documentToCheckId, consultantId) => ({
  type: CHECK_DOCUMENT,
  documentId: documentToCheckId,
  consultantId: consultantId,
});

export const setCheckedDocument = (checkedDocument) => ({
  type: SET_CHECKED_DOCUMENT,
  document: checkedDocument,
});

export const uncheckDocument = (documentToUncheckId) => ({
  type: UNCHECK_DOCUMENT,
  documentId: documentToUncheckId,
});

export const setUncheckedDocument = (uncheckedDocumentId) => ({
  type: SET_UNCHECKED_DOCUMENT,
  documentId: uncheckedDocumentId,
});
