// === action types
export const SET_LOGIN = 'SET_LOGIN';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_IS_CONNEXION_LOADING = 'SET_IS_CONNEXION_LOADING';
export const SUBMIT_LOGIN = 'SUBMIT_LOGIN';
export const SUBMIT_LOGOUT = 'SUBMIT_LOGOUT';
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN';

// === action creators
export const setLogin = (newValue) => ({
    type: SET_LOGIN,
    value: newValue,
});

export const setPassword = (newValue) => ({
    type: SET_PASSWORD,
    value: newValue,
});

export const setIsConnexionLoading = (newValue) => ({
    type: SET_IS_CONNEXION_LOADING,
    value: newValue,
});

export const submitLogin = () => ({
    type: SUBMIT_LOGIN,
});

export const submitLogout = () => ({
    type: SUBMIT_LOGOUT,
});

export const successLogin = () => ({
    type: SUCCESS_LOGIN,
});