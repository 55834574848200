import { connect } from 'react-redux';
import { setArrivalDate, setLastname, setFirstname } from '../../actions/consultantActions';

// on importe le composant de présentation
import ConsultantHeaderInfos from '../../components/ConsultantHeaderInfos';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = () => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  // currentConsultant: state.consultantReducer.currentConsultant,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  handleArrivalDateChange: (value) => {
    dispatch(setArrivalDate(value));
  },
  handleCurrentLastnameChange: (value) => {
    dispatch(setLastname(value));
  },
  handleCurrentFirstnameChange: (value) => {
    dispatch(setFirstname(value));
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantHeaderInfos);