import TopHeader from './TopHeader';
import MainHeader from '../../containers/Header/MainHeader';
import NavBar from '../../containers/Header/NavBar';

import './styles.scss';

const Header = () => (
    <div className="header-container">
        <TopHeader />
        <MainHeader />
        <NavBar />
    </div>
);

export default Header;