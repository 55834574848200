import { SET_IS_UNDONE_ACTIONS_LIST_LOADING, SET_UNDONE_ACTIONS_LIST } from '../actions/actionActions';
import { SET_CHECKED_DOCUMENT, SET_UNCHECKED_DOCUMENT } from '../actions/documentActions';
import {
  SAVE_CONSULTANT,
  SET_FIRSTNAME,
  SET_LASTNAME,
  SET_STATUS,
  SET_COMPANY,
  SET_ARRIVAL_DATE,
  SET_DOES_ACTION,
  SET_IS_CONSULTANTS_LIST_LOADING,
  SET_IS_CONSULTANT_PAGE_LOADING,
  SAVE_CONSULTANTS_IN_CHARGE,
  SET_IS_CONSULTANTS_IN_CHARGE_LIST_LOADING,
  SET_IS_CONSULTANT_REPORT_LOADING,
  SET_IS_ACTIVE,
  SET_NEW_FIRSTNAME,
  SET_NEW_LASTNAME,
  SET_NEW_STATUS,
  SET_NEW_COMPANY,
  SET_NEW_ARRIVAL_DATE,
  SET_NEW_DOES_ACTION,
  SET_HAS_NEW_CONSULTANT_BEEN_CREATED,
  RESET_NEW_CONSULTANT,
  SAVE_CREALIS_CONSULTANTS_ARRAY,
  SAVE_PORTSIDE_CONSULTANTS_ARRAY,
  SAVE_HCS_CONSULTANTS_ARRAY,
} from '../actions/consultantActions';
import {
  SET_CLIENT_DATE,
  SET_CLIENT_NAME,
  SET_COMMENT,
  SET_CURRENT_REPORT,
  SET_IS_IMPORTANT,
  ADD_NEW_ACTION,
  DELETE_ACTION,
  ADD_NEW_CLIENT,
  DELETE_CLIENT,
  SET_NEW_VALUE_ACTION,
  RESET_CURRENT_REPORT,
  ADD_NEW_REPORT_NEW_ACTION,
  ADD_NEW_REPORT_NEW_CLIENT,
  DELETE_NEW_REPORT_ACTION,
  DELETE_NEW_REPORT_CLIENT,
  // INIT_NEW_REPORT_WITH_ACTIONS,
  // INIT_NEW_REPORT_WITH_CLIENTS,
  RESET_NEW_REPORT,
  SET_IS_ACTIONS_LIST_LOADING,
  SET_NEW_REPORT_CLIENT_DATE,
  SET_NEW_REPORT_CLIENT_NAME,
  SET_NEW_REPORT_COMMENT,
  SET_NEW_REPORT_IS_IMPORTANT,
  SET_NEW_REPORT_NEW_VALUE_ACTION,
  SET_NEW_REPORT,
  SET_IS_CURRENT_REPORT_CLIENT_READONLY,
  SET_IS_CURRENT_REPORT_COMMENT_READONLY,
  SET_IS_NEW_REPORT_CLIENT_READONLY,
  SET_IS_NEW_REPORT_COMMENT_READONLY
} from '../actions/reportActions';

import { formatDate } from '../scripts/functions'

const initialState = {
  // ici le state initial
  currentConsultant: {
    report: [],
  },
  consultantsInCharge: [],
  isConsultantsListLoading: true,
  currentReport: {
    id: 0,
    clients: [],
    comment: '',
    actions: [],
  },
  isConsultantReportLoading: true,
  isConsultantPageLoading: true,
  isConsultantsInChargeListLoading: true,
  newReport: {
    clients: [],
    actions: [],
    comment: '',
  },
  currentClient: {
    id: 0,
    name: '',
    date: null,
  },
  isActionsListLoading: false,
  newConsultant: {
    lastname: '',
    firstname: '',
    arrival_date: formatDate(Date().toLocaleString()),
    status: '',
    company: '',
    does_action: false,
    is_active: true,
    is_deleted: false,
  },
  hasNewConsultantBeenCreated: false,
  isUndoneActionsListLoading: true,
  undoneActionsArray: [],
  crealisConsultantsArray: [],
  portsideConsultantsArray: [],
  hcsConsultantsArray: [],
  isDeleteConfirmationDivOpen: false,
  isCurrentReportClientReadOnly: true,
  isCurrentReportCommentReadOnly: true,
  isNewReportClientReadOnly: true,
  isNewReportCommentReadOnly: true,
};

function consultantReducer(state = initialState, action) {
  switch (action.type) {

    /**
     * during meeting, on a consultant page, we got 2 reports : 
     *    - last report we've posted for this consultant 
     *    - new report we are creating for the current meeting week
     * for both, actions are the same but we need to save requests responses on 2 differents state slots : 
     *    - "currentReport" for the last report
     *    - "newReport" for the new one
     */


    // load current consultant on his page
    case SAVE_CONSULTANT: {
      if (action.value === null) {
        return { ...state }
      } else {
        return {
          ...state,
          currentConsultant: action.value,
        }
      }
    }

    // display consultant's infos on infosconsultantpage or meetingconsultantpage
    case SET_FIRSTNAME:
      return {
        ...state,
        currentConsultant: {
          ...state.currentConsultant,
          firstname: action.value,
        },
      };
    case SET_LASTNAME:
      return {
        ...state,
        currentConsultant: {
          ...state.currentConsultant,
          lastname: action.value,
        },
      };
    case SET_STATUS:
      return {
        ...state,
        currentConsultant: {
          ...state.currentConsultant,
          status: action.value,
        },
      };
    case SET_COMPANY:
      return {
        ...state,
        currentConsultant: {
          ...state.currentConsultant,
          company: action.value,
        },
      };
    case SET_ARRIVAL_DATE:
      return {
        ...state,
        currentConsultant: {
          ...state.currentConsultant,
          arrival_date: action.value,
        },
      };
    case SET_DOES_ACTION:
      return {
        ...state,
        currentConsultant: {
          ...state.currentConsultant,
          does_action: action.value,
        },
      };
    case SET_IS_ACTIVE:
      return {
        ...state,
        currentConsultant: {
          ...state.currentConsultant,
          is_active: action.value,
        },
      };


    /**
     *  handle checkdocuments for a consultant
     *  if a new document is checked, we post it as a new entry on checkdocuments table
     *  if a document is unchecked, we delete it from the checkdocuments table 
     */
    case SET_CHECKED_DOCUMENT: {
      const newCheckedDocumentsArray = [
        ...state.currentConsultant.checkedDocuments,
        {
          ...action.document,
        }
      ];
      return {
        ...state,
        currentConsultant: {
          ...state.currentConsultant,
          checkedDocuments: newCheckedDocumentsArray,
        },
      };
    }
    case SET_UNCHECKED_DOCUMENT: {
      const newCheckedDocumentsArray = state.currentConsultant.checkedDocuments.filter(checkDocument => checkDocument.id !== action.documentId);
      return {
        ...state,
        currentConsultant: {
          ...state.currentConsultant,
          checkedDocuments: newCheckedDocumentsArray,
        },
      };
    }


    // loaders
    case SET_IS_CONSULTANTS_LIST_LOADING: {
      return {
        ...state,
        isConsultantsListLoading: action.value,
      };
    }
    case SET_IS_CONSULTANT_PAGE_LOADING:
      return {
        ...state,
        isConsultantPageLoading: action.value,
      };
    case SET_IS_CONSULTANT_REPORT_LOADING:
      return {
        ...state,
        isConsultantReportLoading: action.value,
      };
    case SET_IS_CONSULTANTS_IN_CHARGE_LIST_LOADING:
      return {
        ...state,
        isConsultantsInChargeListLoading: action.value,
      };


    case SAVE_CONSULTANTS_IN_CHARGE: {
      let consultantsInChargeFirstname = [' '];
      action.value.forEach((consultant) => {
        consultantsInChargeFirstname.push(consultant.firstname);
      });

      return {
        ...state,
        consultantsInCharge: consultantsInChargeFirstname,
      };
    }

    // from currentConsultant, we find the latest report he got (id given when we call the action)
    // and we save it on currentReport
    case SET_CURRENT_REPORT: {
      const newConsultantReportsArray = [...state.currentConsultant.report];
      const newCurrentReport = newConsultantReportsArray.filter(report => report.week.id === action.value);
      return {
        ...state,
        currentReport: newCurrentReport[0],
        isConsultantReportLoading: false,
      };
    }

    // from currentConsultant, we find the new report we've just created starting the meeting
    // (id given when we call the action)
    // and we save it on newReport
    case SET_NEW_REPORT: {
      const newConsultantReportsArray = [...state.currentConsultant.report];
      const newCurrentReport = newConsultantReportsArray.filter(report => report.week.id === action.value);
      return {
        ...state,
        newReport: newCurrentReport[0],
        isConsultantReportLoading: false,
      };
    }

    // each time we got to an other consultant, we reset currentReport (before loading the good one if it exists)
    case RESET_CURRENT_REPORT:
      return {
        ...state,
        currentReport: {
          id: 0,
          clients: [

          ],
          comment: '',
          actions: [

          ],
        }
      }

    // after patching the comment in consultantMiddleware, we save the change on state on currentReport
    case SET_COMMENT: {
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          comment: action.value,
        },
      };
    }
    // after patching the client's name in consultantMiddleware, we find the client we've updated
    // and we save the change on state on currentReport
    case SET_CLIENT_NAME: {
      const newClientsArray = [...state.currentReport.clients];
      newClientsArray.forEach((client) => {
        if (client.id === action.id) {
          client.name = action.value;
        }
      })
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          clients: newClientsArray,
        },
      };
    }
    // after patching the client's date in consultantMiddleware, we find the client we've updated
    // and we save the change on state on currentReport
    case SET_CLIENT_DATE: {
      const newClientsArray = [...state.currentReport.clients];
      newClientsArray.forEach((client) => {
        if (client.id === action.id) {
          client.date = action.value;
        }
      })
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          clients: newClientsArray,
        },
      };
    }
    case SET_IS_IMPORTANT: {
      let newConsultantActionsArray = [...state.currentReport.actions];
      newConsultantActionsArray.forEach((consultantAction) => {
        if (consultantAction.id === action.id) {
          consultantAction.is_important = action.value;
        }
      })
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          actions: newConsultantActionsArray,
        },
      };
    }
    case SET_NEW_VALUE_ACTION: {
      let newConsultantActionsArray = [
        ...state.currentReport.actions,
      ];

      newConsultantActionsArray.forEach((consultantAction) => {
        if (consultantAction.id === action.id) {
          consultantAction.description = action.value.description;
          consultantAction.person_in_charge = action.value.person_in_charge;
          consultantAction.creation_date = action.value.creation_date;
          consultantAction.is_done = action.value.is_done;
          consultantAction.done_date = action.value.done_date;
          consultantAction.is_important = action.value.is_important;
        }
      })

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          actions: newConsultantActionsArray,
        },
      };
    }
    case ADD_NEW_ACTION: {
      const newConsultantActionsArray = [
        ...state.currentReport.actions,
        {
          ...action.value
        }
      ];
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          actions: newConsultantActionsArray,
        },
      }
    }
    case DELETE_ACTION: {
      const newConsultantActionsArray = state.currentReport.actions.filter(consultantAction => consultantAction.id !== action.id)
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          actions: newConsultantActionsArray,
        },
      };
    }
    case ADD_NEW_CLIENT: {
      const newConsultantClientsArray = [
        ...state.currentReport.clients,
        {
          ...action.value
        }
      ];
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          clients: newConsultantClientsArray,
        },
      };
    }
    case DELETE_CLIENT: {
      const newConsultantClientsArray = state.currentReport.clients.filter(client => client.id !== action.id)
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          clients: newConsultantClientsArray,
        },
      };
    }
    case SET_NEW_REPORT_COMMENT: {
      return {
        ...state,
        newReport: {
          ...state.newReport,
          comment: action.value,
        },
      };
    }
    case SET_NEW_REPORT_CLIENT_NAME: {
      const newClientsArray = [...state.newReport.clients];
      newClientsArray.forEach((client) => {
        if (client.id === action.id) {
          client.name = action.value;
        }
      })
      return {
        ...state,
        newReport: {
          ...state.newReport,
          clients: newClientsArray,
        },
        currentClient: {
          ...state.currentClient,
          id: action.id,
          name: action.value,
        }
      };
    }
    case SET_NEW_REPORT_CLIENT_DATE: {
      const newClientsArray = [...state.newReport.clients];
      newClientsArray.forEach((client) => {
        if (client.id === action.id) {
          client.date = action.value;
        }
      })
      return {
        ...state,
        newReport: {
          ...state.newReport,
          clients: newClientsArray,
        },
      };
    }
    case ADD_NEW_REPORT_NEW_CLIENT: {
      const newClient = {
        ...action.value
      };

      newClient.date = formatDate(newClient.date);

      const newConsultantClientsArray = [
        ...state.newReport.clients,
        {
          ...newClient
        }
      ];
      return {
        ...state,
        newReport: {
          ...state.newReport,
          clients: newConsultantClientsArray,
        },
      };
    }
    case DELETE_NEW_REPORT_CLIENT: {
      const newConsultantClientsArray = state.newReport.clients.filter(client => client.id !== action.id)
      return {
        ...state,
        newReport: {
          ...state.newReport,
          clients: newConsultantClientsArray,
        },
      };
    }
    case SET_NEW_REPORT_IS_IMPORTANT: {
      let newConsultantActionsArray = [...state.newReport.actions];
      newConsultantActionsArray.forEach((consultantAction) => {
        if (consultantAction.id === action.id) {
          consultantAction.is_important = action.value;
        }
      })
      return {
        ...state,
        newReport: {
          ...state.newReport,
          actions: newConsultantActionsArray,
        },
      };
    }
    case SET_NEW_REPORT_NEW_VALUE_ACTION: {
      let newConsultantActionsArray = [
        ...state.newReport.actions,
      ];

      newConsultantActionsArray.forEach((consultantAction) => {
        if (consultantAction.id === action.id) {
          consultantAction.description = action.value.description;
          consultantAction.person_in_charge = action.value.person_in_charge;
          consultantAction.creation_date = action.value.creation_date;
          consultantAction.is_done = action.value.is_done;
          consultantAction.done_date = action.value.done_date;
          consultantAction.is_important = action.value.is_important;
        }
      })

      return {
        ...state,
        newReport: {
          ...state.newReport,
          actions: newConsultantActionsArray,
        },
      };
    }
    case ADD_NEW_REPORT_NEW_ACTION: {
      const newConsultantActionsArray = [
        ...state.newReport.actions,
        {
          ...action.value
        }
      ];
      return {
        ...state,
        newReport: {
          ...state.newReport,
          actions: newConsultantActionsArray,
        },
      }
    }
    case DELETE_NEW_REPORT_ACTION: {
      const newConsultantActionsArray = state.newReport.actions.filter(consultantAction => consultantAction.id !== action.id)
      return {
        ...state,
        newReport: {
          ...state.newReport,
          actions: newConsultantActionsArray,
        },
      };
    }
    case RESET_NEW_REPORT:
      return {
        ...state,
        newReport: {
          clients: [

          ],
          comment: '',
          actions: [

          ],
        }
      }
    case SET_IS_ACTIONS_LIST_LOADING:
      return {
        ...state,
        isActionsListLoading: false,
      }

    // Actions for InfosConsultantForm
    case SET_NEW_FIRSTNAME:
      return {
        ...state,
        newConsultant: {
          ...state.newConsultant,
          firstname: action.value,
        },
      };
    case SET_NEW_LASTNAME:
      return {
        ...state,
        newConsultant: {
          ...state.newConsultant,
          lastname: action.value,
        },
      };
    case SET_NEW_STATUS:
      return {
        ...state,
        newConsultant: {
          ...state.newConsultant,
          status: action.value,
        },
      };
    case SET_NEW_COMPANY:
      return {
        ...state,
        newConsultant: {
          ...state.newConsultant,
          company: action.value,
        },
      };
    case SET_NEW_ARRIVAL_DATE:
      return {
        ...state,
        newConsultant: {
          ...state.newConsultant,
          arrival_date: action.value,
        },
      };
    case SET_NEW_DOES_ACTION:
      return {
        ...state,
        newConsultant: {
          ...state.newConsultant,
          does_action: action.value,
        },
      };
    case SET_HAS_NEW_CONSULTANT_BEEN_CREATED:
      return {
        ...state,
        hasNewConsultantBeenCreated: true,
      };
    case RESET_NEW_CONSULTANT:
      return {
        ...state,
        newConsultant: {
          lastname: '',
          firstname: '',
          arrival_date: formatDate(Date().toLocaleString()),
          status: '',
          company: '',
          does_action: false,
          is_active: true,
          is_deleted: false,
        },
        hasNewConsultantBeenCreated: false,
      };
    case SET_IS_UNDONE_ACTIONS_LIST_LOADING:
      return {
        ...state,
        isUndoneActionsListLoading: action.value,
      };
    case SET_UNDONE_ACTIONS_LIST:
      return {
        ...state,
        undoneActionsArray: [...action.value],
      };
    case SAVE_CREALIS_CONSULTANTS_ARRAY:
      return {
        ...state,
        crealisConsultantsArray: [...action.value],
      };
    case SAVE_PORTSIDE_CONSULTANTS_ARRAY:
      return {
        ...state,
        portsideConsultantsArray: [...action.value],
      };
    case SAVE_HCS_CONSULTANTS_ARRAY:
      return {
        ...state,
        hcsConsultantsArray: [...action.value],
      };
    case SET_IS_CURRENT_REPORT_CLIENT_READONLY:
      return {
        ...state,
        isCurrentReportClientReadOnly: action.value,
      };
    case SET_IS_CURRENT_REPORT_COMMENT_READONLY:
      return {
        ...state,
        isCurrentReportCommentReadOnly: action.value,
      };
    case SET_IS_NEW_REPORT_CLIENT_READONLY:
      return {
        ...state,
        isNewReportClientReadOnly: action.value,
      };
    case SET_IS_NEW_REPORT_COMMENT_READONLY:
      return {
        ...state,
        isNewReportCommentReadOnly: action.value,
      };
    default:
      return state;
  }
}

export default consultantReducer;