import { connect } from 'react-redux';
import { setIsConsultantReportLoading } from '../../actions/consultantActions';
import {
  setCurrentReport,
  setClientName,
  setClientDate,
  setComment,
  postNewClient,
  deleteDbClient,
  // initNewReportWithClients,
  patchClient,
  patchComment,
  postClientToNewReport,
  setNewReport,
  setNewReportComment,
  setNewReportClientName,
  setNewReportClientDate,
  setIsCurrentReportClientReadOnly,
  setIsCurrentReportCommentReadOnly,
  setIsNewReportClientReadOnly,
  setIsNewReportCommentReadOnly,
  deleteReport,
} from '../../actions/reportActions';

// on importe le composant de présentation
import Report from '../../components/Report';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  currentReport: state.consultantReducer.currentReport,
  newReport: state.consultantReducer.newReport,
  currentConsultant: state.consultantReducer.currentConsultant,
  isConsultantReportLoading: state.consultantReducer.isConsultantReportLoading,
  areNewReportsCreated: state.meetingReducer.areNewReportsCreated,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  loadReport: (weekId) => {
    dispatch(setCurrentReport(weekId));
  },
  loadNewReport: (weekId) => {
    dispatch(setNewReport(weekId));
  },
  handleCommentChange: (newComment) => {
    dispatch(setComment(newComment));
  },
  handleClientNameChange: (newClientName, clientId) => {
    dispatch(setClientName(newClientName, clientId));
  },
  handleClientDateChange: (newClientDate, clientId) => {
    dispatch(setClientDate(newClientDate, clientId));
  },
  handleNewReportCommentChange: (newComment) => {
    dispatch(setNewReportComment(newComment));
  },
  handleNewReportClientNameChange: (newClientName, clientId) => {
    dispatch(setNewReportClientName(newClientName, clientId));
  },
  handleNewReportClientDateChange: (newClientDate, clientId) => {
    dispatch(setNewReportClientDate(newClientDate, clientId));
  },
  addNewClient: (reportId, isNewReport) => {
    dispatch(postNewClient(reportId, isNewReport));
  },
  addClientToNewReport: (reportId, clientToAdd) => {
    dispatch(postClientToNewReport(reportId, clientToAdd));
  },
  deleteClient: (clientId, isNewReport) => {
    dispatch(deleteDbClient(clientId, isNewReport));
  },
  patchClient: (clientId, newClientName, newClientDate) => {
    dispatch(patchClient(clientId, newClientName, newClientDate));
  },
  patchComment: (reportId, newComment) => {
    dispatch(patchComment(reportId, newComment));
  },
  // initNewReportWithClients: (clientsArray) => {
  //   dispatch(initNewReportWithClients(clientsArray));
  // },
  setIsConsultantReportLoading: (clientId) => {
    dispatch(setIsConsultantReportLoading(clientId));
  },
  setIsCurrentReportClientReadOnly: (newValue) => {
    dispatch(setIsCurrentReportClientReadOnly(newValue));
  },
  setIsCurrentReportCommentReadOnly: (newValue) => {
    dispatch(setIsCurrentReportCommentReadOnly(newValue));
  },
  setIsNewReportClientReadOnly: (newValue) => {
    dispatch(setIsNewReportClientReadOnly(newValue));
  },
  setIsNewReportCommentReadOnly: (newValue) => {
    dispatch(setIsNewReportCommentReadOnly(newValue));
  },
  deleteReport: (reportId) => {
    dispatch(deleteReport(reportId));
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(Report);