import { connect } from 'react-redux';
import {
  setIsMeetingConsultantsListLoading,
  fetchActiveConsultants,
  filterMeetingConsultantsList,
  setIsCompanyChecked,
  setFilterValuesArray,
} from '../../../actions/meetingActions';

// on importe le composant de présentation
import MeetingList from '../../../components/MeetingPage/MeetingList';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  meetingConsultantsArray: state.meetingReducer.meetingConsultantsArray,
  includeCrealis: state.meetingReducer.includeCrealis,
  includePortside: state.meetingReducer.includePortside,
  includeCrealisPortside: state.meetingReducer.includeCrealisPortside,
  includeHCS: state.meetingReducer.includeHCS,
  filterValuesArray: state.meetingReducer.filterValuesArray,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  setIsMeetingConsultantsListLoading: (newValue) => {
    dispatch(setIsMeetingConsultantsListLoading(newValue));
  },
  loadActiveConsultants: (filterValuesArray) => {
    dispatch(fetchActiveConsultants(filterValuesArray));
  },
  filterMeetingConsultantsList: (companyValuesArray) => {
    dispatch(filterMeetingConsultantsList(companyValuesArray));
  },
  setIsCompanyChecked: (companyName, isChecked) => {
    dispatch(setIsCompanyChecked(companyName, isChecked));
  },
  setFilterValuesArray: (filterValue) => {
    dispatch(setFilterValuesArray(filterValue));
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(MeetingList);