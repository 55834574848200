import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListAltIcon from '@mui/icons-material/ListAlt';

import ConsultantHeaderInfos from '../../containers/ConsultantHeaderInfos';
import DocumentsToCheck from '../../containers/DocumentsToCheck';
import Report from '../../containers/Report';

import './styles.scss';
import { formatFrenchDate } from '../../scripts/functions';
import Loader from '../Loader';

const MeetingConsultantPage = ({
    isMeetingConsultantPageLoading,
    setIsMeetingConsultantPageLoading,
    loadMeetingConsultant,
    resetCurrentReport,
    resetNewReport,
    currentConsultant,
    currentMeetingWeek,
    meetingConsultantsArray,
    setIsActive,
    patchConsultant,
    validateReportStatus,
    loadConsultantsInCharge,
    isCurrentReportClientReadOnly,
    isCurrentReportCommentReadOnly,
    isNewReportClientReadOnly,
    isNewReportCommentReadOnly,
    setIsCurrentReportClientReadOnly,
    setIsCurrentReportCommentReadOnly,
    setIsNewReportClientReadOnly,
    setIsNewReportCommentReadOnly,
}) => {
    const { slug } = useParams();

    const [displayArchivedConsultantMessage, setDisplayArchivedConsultantMessage] = useState(false);
    const [isAlertDivOpen, setIsAlertDivOpen] = useState(false);

    useEffect(() => {
        // /!\ here, the slug is the meeting_consultant_id, not the id /!\
        loadMeetingConsultant(slug);
        loadConsultantsInCharge();
        return () => {
            setIsMeetingConsultantPageLoading(true);
            setDisplayArchivedConsultantMessage(false);
            setIsCurrentReportClientReadOnly(true);
            setIsCurrentReportCommentReadOnly(true);
            setIsNewReportClientReadOnly(true);
            setIsNewReportCommentReadOnly(true);
            setIsAlertDivOpen(false);
            resetCurrentReport();
            resetNewReport();
        }
    }, [slug]);
    
    let lastReport = {};

    if (!isMeetingConsultantPageLoading) {
        if (currentConsultant.report.length !== 0) {
            let currentReportStartDate = '2000-01-01';
            currentConsultant.report.forEach((report) => {
                if (report.week.start_date >= currentReportStartDate && report.week.id != currentMeetingWeek.id) {
                    currentReportStartDate = report.week.start_date;
                    lastReport = {...report};
                }
            });
        }
    }
    return (
        <div className="meeting-consultant-page-container">
                {isMeetingConsultantPageLoading
                && (
                    <Loader />
                )}
                {!isMeetingConsultantPageLoading
                && meetingConsultantsArray.length === 0
                && (
                    <>
                        <p style={{ margin: '1em' }}>Le chargement a échoué.</p>
                        <Link to="/reunion">
                            <button
                                className='meeting-consultant-page-archive-button'
                            >
                                Retour à la liste
                            </button>
                        </Link>
                    </>
                )}
                {!isMeetingConsultantPageLoading
                && meetingConsultantsArray.length !== 0
                && (
                    <>
                        <div className="meeting-consultant-page-header">
                            <div className="meeting-consultant-page-header-nav">
                                {slug == 1
                                && (
                                    <Link to={(isNewReportClientReadOnly
                                    && isNewReportCommentReadOnly
                                    && isCurrentReportClientReadOnly
                                    && isCurrentReportCommentReadOnly) ? `/reunion` : '' }>
                                        <ListAltIcon
                                            fontSize='large' 
                                            onClick={() => {
                                                if (isNewReportClientReadOnly
                                                && isNewReportCommentReadOnly
                                                && isCurrentReportClientReadOnly
                                                && isCurrentReportCommentReadOnly) {
                                                    validateReportStatus(currentConsultant.id)
                                                } else {
                                                    setIsAlertDivOpen(true);
                                                }
                                            }}
                                        />
                                    </Link>
                                )}
                                {slug != 1
                                && (
                                    <Link to={(isNewReportClientReadOnly
                                    && isNewReportCommentReadOnly
                                    && isCurrentReportClientReadOnly
                                    && isCurrentReportCommentReadOnly) ? `/reunion/${+slug - 1}` : '' }>
                                        <ArrowBackIosNewIcon
                                            fontSize='large'
                                            onClick={() => {
                                                if (isNewReportClientReadOnly
                                                && isNewReportCommentReadOnly
                                                && isCurrentReportClientReadOnly
                                                && isCurrentReportCommentReadOnly) {
                                                    validateReportStatus(currentConsultant.id)
                                                } else {
                                                    setIsAlertDivOpen(true);
                                                }
                                            }}    
                                        />
                                    </Link>
                                )}
                                <ConsultantHeaderInfos currentConsultant={currentConsultant} isMeetingConsultantPage />
                                {slug == meetingConsultantsArray.length
                                && (
                                    <Link to={(isNewReportClientReadOnly
                                    && isNewReportCommentReadOnly
                                    && isCurrentReportClientReadOnly
                                    && isCurrentReportCommentReadOnly) ? `/reunion` : '' }>
                                        <ListAltIcon
                                            fontSize='large'
                                            onClick={() => {
                                                if (isNewReportClientReadOnly
                                                && isNewReportCommentReadOnly
                                                && isCurrentReportClientReadOnly
                                                && isCurrentReportCommentReadOnly) {
                                                    validateReportStatus(currentConsultant.id)
                                                } else {
                                                    setIsAlertDivOpen(true);
                                                }
                                            }} 
                                        />
                                    </Link>
                                )}
                                {slug != meetingConsultantsArray.length
                                && (
                                    <Link to={(isNewReportClientReadOnly
                                    && isNewReportCommentReadOnly
                                    && isCurrentReportClientReadOnly
                                    && isCurrentReportCommentReadOnly) ? `/reunion/${+slug + 1}` : '' }>
                                        <ArrowForwardIosIcon
                                            fontSize='large'
                                            onClick={() => {
                                                if (isNewReportClientReadOnly
                                                && isNewReportCommentReadOnly
                                                && isCurrentReportClientReadOnly
                                                && isCurrentReportCommentReadOnly) {
                                                    validateReportStatus(currentConsultant.id)
                                                } else {
                                                    setIsAlertDivOpen(true);
                                                }
                                            }} 
                                        />
                                    </Link>
                                )}
                            </div>
                            <div style={{ margin: '0 1em 1em' }}>
                                {currentConsultant.is_active
                                && (
                                    <Link to={`/reunion/${+slug}`}>
                                        <button
                                            className='meeting-consultant-page-archive-button'
                                            onClick={() => {
                                                setIsActive(false);
                                                patchConsultant();
                                                setDisplayArchivedConsultantMessage(true);
                                            }}
                                        >
                                            Archiver ce consultant
                                        </button>
                                    </Link>
                                )}
                                {!currentConsultant.is_active
                                && (
                                    <Link to={`/reunion/${+slug}`}>
                                        <button
                                            className='meeting-consultant-page-archive-button'
                                            onClick={() => {
                                                setIsActive(true);
                                                patchConsultant();
                                                setDisplayArchivedConsultantMessage(false);
                                            }}
                                        >
                                            Rendre ce consultant actif
                                        </button>
                                    </Link>
                                )}
                                {displayArchivedConsultantMessage
                                && (
                                    <p style={{ margin: '0 1em 2em', fontWeight: 'bold' }}>Le consultant a bien été archivé</p>
                                )}
                            </div>

                            <DocumentsToCheck currentConsultant={currentConsultant}  />
                        </div>
                        <div className="meeting-consultant-page-last-report">
                            <h3 className="meeting-consultant-page-subtitle-report">
                                Dernier compte-rendu :
                            </h3>
                            {Object.keys(lastReport).length === 0
                            && (
                                <div className="report-container" style={{ backgroundColor: '#d5d5d5', marginTop: '2em' } }>
                                    <p>Aucun compte-rendu.</p>
                                </div>
                            )}
                            {Object.keys(lastReport).length !== 0
                            && (
                                <>
                                    <h2 className="meeting-consultant-page-subtitle-report-week">Semaine {lastReport.week.number} (du {formatFrenchDate(lastReport.week.start_date)} au {formatFrenchDate(lastReport.week.end_date)})</h2>
                                    <Report lastReportWeekId={lastReport.week.id} isLastReport isMeetingConsultantPage isInfosConsultantPage={false} />
                                </>
                            )}
                        </div>
                        <div className="meeting-consultant-page-new-report">
                            <h3 className="meeting-consultant-page-subtitle-report">
                                Nouveau compte-rendu :
                            </h3>
                            {Object.keys(currentMeetingWeek).length === 0
                            && (<h2>La réunion n&apos;a pas encore été créée</h2>)
                            }
                            {Object.keys(currentMeetingWeek).length !== 0
                            && (
                                <>
                                <h2 className="meeting-consultant-page-subtitle-report-week">
                                    Semaine {currentMeetingWeek.number} (du {formatFrenchDate(currentMeetingWeek.start_date)} au {formatFrenchDate(currentMeetingWeek.end_date)})
                                </h2>
                                <Report newReportWeekId={currentMeetingWeek.id} isNewReport isMeetingConsultantPage isInfosConsultantPage={false} />
                                </>
                            )}
                        </div>
                        <div className="meeting-consultant-page-buttons-container">
                            {slug != 1
                            && (
                                <Link to={(isNewReportClientReadOnly
                                    && isNewReportCommentReadOnly
                                    && isCurrentReportClientReadOnly
                                    && isCurrentReportCommentReadOnly) ? `/reunion/${+slug - 1}` : '' }>
                                    <button
                                        className="meeting-consultant-page-button"
                                        onClick={() => {
                                            if (isNewReportClientReadOnly
                                            && isNewReportCommentReadOnly
                                            && isCurrentReportClientReadOnly
                                            && isCurrentReportCommentReadOnly) {
                                                validateReportStatus(currentConsultant.id)
                                            } else {
                                                setIsAlertDivOpen(true);
                                            }
                                        }} 
                                    >
                                        Revenir au consultant précédent
                                    </button>
                                </Link>
                            )}
                            <Link to={(isNewReportClientReadOnly
                                    && isNewReportCommentReadOnly
                                    && isCurrentReportClientReadOnly
                                    && isCurrentReportCommentReadOnly) ? `/reunion` : '' }>
                                <button
                                    className="meeting-consultant-page-button"
                                    onClick={() => {
                                        if (isNewReportClientReadOnly
                                        && isNewReportCommentReadOnly
                                        && isCurrentReportClientReadOnly
                                        && isCurrentReportCommentReadOnly) {
                                            validateReportStatus(currentConsultant.id)
                                        } else {
                                            setIsAlertDivOpen(true);
                                        }
                                    }} 
                                >
                                    Revenir à la liste
                                </button>
                            </Link>
                            {slug != meetingConsultantsArray.length
                            && (
                                <Link to={(isNewReportClientReadOnly
                                        && isNewReportCommentReadOnly
                                        && isCurrentReportClientReadOnly
                                        && isCurrentReportCommentReadOnly) ? `/reunion/${+slug + 1}` : '' }>
                                    <button
                                        className="meeting-consultant-page-button"
                                        onClick={() => {
                                            if (isNewReportClientReadOnly
                                            && isNewReportCommentReadOnly
                                            && isCurrentReportClientReadOnly
                                            && isCurrentReportCommentReadOnly) {
                                                validateReportStatus(currentConsultant.id)
                                            } else {
                                                setIsAlertDivOpen(true);
                                            }
                                        }} 
                                    >
                                        Passer au consultant suivant
                                    </button>
                                </Link>
                            )}
                        </div>
                        {isAlertDivOpen
                        && (
                            <div className='meeting-consultant-page-modal-error'>
                                <p style={{ fontWeight: 'bold' }}>Attention ! Certaines modifications n&apos;ont pas été validées.</p>
                                <button
                                    onClick={() => {setIsAlertDivOpen(false);}}
                                >
                                    Annuler
                                </button>
                            </div>
                        )}
                    </>
                )}
        </div>
    )
};

MeetingConsultantPage.propTypes = {
    isMeetingConsultantPageLoading: PropTypes.bool.isRequired,
    setIsMeetingConsultantPageLoading: PropTypes.func.isRequired,
    loadMeetingConsultant: PropTypes.func.isRequired,
    loadConsultantsInCharge: PropTypes.func.isRequired,
    resetCurrentReport: PropTypes.func.isRequired,
    resetNewReport: PropTypes.func.isRequired,
    currentConsultant: PropTypes.shape({
        id: PropTypes.number,
        lastname: PropTypes.string,
        report: PropTypes.array,
        is_active: PropTypes.bool,
    }),
    currentMeetingWeek: PropTypes.object.isRequired,
    meetingConsultantsArray: PropTypes.array.isRequired,
    setIsActive: PropTypes.func.isRequired,
    patchConsultant: PropTypes.func.isRequired,
    validateReportStatus: PropTypes.func.isRequired,
    isCurrentReportClientReadOnly: PropTypes.bool.isRequired,
    isCurrentReportCommentReadOnly: PropTypes.bool.isRequired,
    isNewReportClientReadOnly: PropTypes.bool.isRequired,
    isNewReportCommentReadOnly: PropTypes.bool.isRequired,
    setIsCurrentReportClientReadOnly: PropTypes.func.isRequired,
    setIsCurrentReportCommentReadOnly: PropTypes.func.isRequired,
    setIsNewReportClientReadOnly: PropTypes.func.isRequired,
    setIsNewReportCommentReadOnly: PropTypes.func.isRequired,
};

MeetingConsultantPage.defaultProps = {
    currentConsultant: {
        id: 0,
        lastname: '',
        report: [],
        is_active: true,
    },
};

export default MeetingConsultantPage;