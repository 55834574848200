import { SET_IS_CONNEXION_LOADING, SET_LOGIN, SET_PASSWORD, SUBMIT_LOGOUT, SUCCESS_LOGIN } from "../actions/connexionActions";

const initialState = {
  // ici le state initial
  login: '',
  password: '',
  isAuthentified: false,
  isConnexionLoading: false,
  appenv: "prod",
};

function connexionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        login: action.value,
      };
    case SET_PASSWORD:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        password: action.value,
      };
    case SET_IS_CONNEXION_LOADING:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        isConnexionLoading: action.value,
      };
    case SUCCESS_LOGIN:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        isAuthentified: true,
      };
    case SUBMIT_LOGOUT:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        isAuthentified: false,
      };
    default:
      return state;
  }
}

export default connexionReducer;