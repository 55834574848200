// change date format coming from db to display it
export const formatDate = (dateToConvert) => {
    let newDate = new Date(dateToConvert);
    let formatted_date = newDate.getFullYear() + "-" + ("0" + (newDate.getMonth() + 1)).slice(-2) + "-" + ("0" + newDate.getDate()).slice(-2)
    return formatted_date;
};

export const formatFrenchDate = (dateToConvert) => {
    let newDate = new Date(dateToConvert);
    let formatted_date = ("0" + newDate.getDate()).slice(-2) + "/" + ("0" + (newDate.getMonth() + 1)).slice(-2) + "/" + newDate.getFullYear()
    return formatted_date;
};

export const getCompany = (numberToConvert) => {
    switch (numberToConvert) {
        case 1:
            return "Créalis Ingénierie"
        case 2:
            return "Créalis Ingénierie/Portside"
        case 3:
            return "Portside"
        case 4:
            return "HCS Groupe"
        default:
            break;
    }
};

export const getColumnForRequest = (numberToConvert) => {
    switch (numberToConvert) {
        case 1:
            return "is_crealis_doc"
        case 2:
            return "is_crealisportside_doc"
        case 3:
            return "is_portside_doc"
        case 4:
            return "is_hcs_doc"
        default:
            break;
    }
};