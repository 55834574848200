import axios from "axios";
import { read_cookie } from 'sfcookies';

import {
    CHECK_DOCUMENT,
    FETCH_DOCUMENTS_LIST,
    UNCHECK_DOCUMENT,
    setCheckedDocument,
    setUncheckedDocument,
    saveCrealisDocumentsArray,
    saveCrealisPortsideDocumentsArray,
    savePortsideDocumentsArray,
    saveHCSDocumentsArray,
} from "../actions/documentActions";

// const baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
// const baseUrl = 'http://localhost:8000/'

const documentMiddleware = (store) => (next) => (action) => {

    const currentEnv = store.getState().connexionReducer.appenv;
    let baseUrl = '';
    if (currentEnv === "prod") {
      baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
    } else {
      baseUrl = 'http://localhost:8000/'
    }

    switch (action.type) {
        case FETCH_DOCUMENTS_LIST:
            axios.get(
                // URL
                `${baseUrl}api/documents?column=${action.company}&value=1`,
                {
                  headers: {
                    Authorization: `Bearer ${read_cookie('jwt')}`,
                  },
                },
              )
                .then((response) => {
                    switch (action.companyValue) {
                        case 1:
                          store.dispatch(saveCrealisDocumentsArray(response.data))
                          break;
                        case 2:
                          store.dispatch(saveCrealisPortsideDocumentsArray(response.data))
                          break;
                        case 3:
                          store.dispatch(savePortsideDocumentsArray(response.data))
                          break;
                        case 4:
                          store.dispatch(saveHCSDocumentsArray(response.data))
                          break;
                        default:
                      }
                    // store.dispatch(saveDocumentsList(response.data));
                })
                .catch(() => {
                    // store.dispatch(errorLogin('Identifiants incorrects'));
                });
            break;
        case CHECK_DOCUMENT: {
            const newCheckedDocumentToPost = {
                consultant: action.consultantId,
                document: action.documentId,
            }
            axios.post(
                // URL
                `${baseUrl}api/checked-documents/add`,
                newCheckedDocumentToPost,
                {
                  headers: {
                    Authorization: `Bearer ${read_cookie('jwt')}`,
                  },
                },
              )
                .then((response) => {
                    store.dispatch(setCheckedDocument(response.data));
                })
                .catch(() => {
                    // store.dispatch(errorLogin('Identifiants incorrects'));
                });
            break;
        }
        case UNCHECK_DOCUMENT: {
            const documentToUncheckId = action.documentId;
            axios.delete(
                // URL
                `${baseUrl}api/checked-documents/delete/${documentToUncheckId}`,
                {
                  headers: {
                    Authorization: `Bearer ${read_cookie('jwt')}`,
                  },
                },
              )
                .then(() => {
                    store.dispatch(setUncheckedDocument(documentToUncheckId));
                })
                .catch(() => {
                    // store.dispatch(errorLogin('Identifiants incorrects'));
                });
            break;
        }
        default:
    }

    // on passe l'action au suivant (middleware suivant ou reducer)
    next(action);
};

export default documentMiddleware;