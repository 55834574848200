import { connect } from 'react-redux';
// import { fetchConsultantsInCharge } from '../../../actions/consultantActions';
import {
  postNewAction,
  deleteDbAction,
  setIsImportant,
  setNewValueAction,
  setNewReportIsImportant,
  addNewReportNewAction,
  deleteNewReportAction,
  setNewReportNewValueAction,
  setIsActionsListLoading,
  patchAction,
  postActionToNewReport,
} from '../../../actions/reportActions';

// on importe le composant de présentation
import ActionsList from '../../../components/Report/ActionsList';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  isActionsListLoading: state.consultantReducer.isActionsListLoading,
  isConsultantsInChargeListLoading: state.consultantReducer.isConsultantsInChargeListLoading,
  consultantsInCharge: state.consultantReducer.consultantsInCharge,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  setIsImportant: (actionId, newValue) => {
    dispatch(setIsImportant(actionId, newValue));
  },
  setNewValueAction: (params) => {
    dispatch(setNewValueAction(params));
  },
  patchAction: (actionId, propertyToUpdate, newValue, isNewReport) => {
    dispatch(patchAction(actionId, propertyToUpdate, newValue, isNewReport));
  },
  addNewAction: (reportId, isNewReport) => {
    dispatch(postNewAction(reportId, isNewReport));
  },
  addActionToNewReport: (reportId, actionToAdd) => {
    dispatch(postActionToNewReport(reportId, actionToAdd));
  },
  deleteAction: (actionId, isNewReport) => {
    dispatch(deleteDbAction(actionId, isNewReport));
  },
  setNewReportIsImportant: (actionId, newValue) => {
    dispatch(setNewReportIsImportant(actionId, newValue));
  },
  setNewReportNewValueAction: (params) => {
    dispatch(setNewReportNewValueAction(params));
  },
  addNewReportNewAction: () => {
    dispatch(addNewReportNewAction());
  },
  deleteNewReportAction: (actionId) => {
    dispatch(deleteNewReportAction(actionId));
  },
  setIsActionsListLoading: (newValue) => {
    dispatch(setIsActionsListLoading(newValue));
  },
  // loadConsultantsInCharge: () => {
  //   dispatch(fetchConsultantsInCharge());
  // },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(ActionsList);