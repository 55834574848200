import { Link } from 'react-router-dom';
import { delete_cookie } from 'sfcookies';
import PropTypes from 'prop-types';

import Logo from '../../../assets/img/hcs.png'

import './styles.scss';

const MainHeader = ({ isAuthentified, logout }) => (
    <div className="main-header-container">
        <Link to="/">
            <img src={Logo} alt="Logo HCS" />
        </Link>
        <div className='main-header-user'>
            {isAuthentified
                && (
                    <>
                        <p>Administrateur</p>
                        <Link
                            to="/"
                            className='main-header-link main-header-logout-link'
                            onClick={() => {
                                logout();
                                delete_cookie('jwt');
                            }}
                        >
                            Se déconnecter
                        </Link>
                    </>
                )}
            {!isAuthentified
                && <Link to="/" className='main-header-link main-header-login-link'>
                    Se connecter
                </Link>}
        </div>
    </div>
);

MainHeader.propTypes = {
    isAuthentified: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
};


// MainHeader.defaultProps = {
// };

export default MainHeader;