import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import ConsultantHeaderInfos from '../../containers/ConsultantHeaderInfos';
import ConsultantFormInfos from '../../containers/InfosConsultantPage/ConsultantFormInfos';
import DocumentsToCheck from '../../containers/DocumentsToCheck';
import ReportsList from '../../containers/ReportsList';

import './styles.scss';
import Loader from '../Loader';

const InfosConsultantPage = ({
    isConsultantPageLoading,
    setIsConsultantPageLoading,
    setIsActive,
    loadConsultant,
    patchConsultant,
    currentConsultant,
    loadConsultantsInCharge,
    isCurrentReportClientReadOnly,
    isCurrentReportCommentReadOnly,
    isNewReportClientReadOnly,
    isNewReportCommentReadOnly,
    setIsCurrentReportClientReadOnly,
    setIsCurrentReportCommentReadOnly,
    setIsNewReportClientReadOnly,
    setIsNewReportCommentReadOnly,
    // patchReport
}) => {
    // collect slug from react router
    // this slug is the current consultant's id
    const { slug } = useParams();
    const [isAlertDivOpen, setIsAlertDivOpen] = useState(false);

    useEffect(() => {
        loadConsultant(slug);
        loadConsultantsInCharge();
        return () => {
            setIsConsultantPageLoading(true);
            setIsCurrentReportClientReadOnly(true);
            setIsCurrentReportCommentReadOnly(true);
            setIsNewReportClientReadOnly(true);
            setIsNewReportCommentReadOnly(true);
            setIsAlertDivOpen(false);
        }
    },
    []);

    return (
        <div className="infos-consultant-page-container">
            {isConsultantPageLoading
            && (
                <Loader />
            )}
            {!isConsultantPageLoading
            && (
                <>
                    <ConsultantHeaderInfos currentConsultant={currentConsultant} />
                    <Link to={`/consultants/${+slug}`}>
                        {currentConsultant.is_active
                        && (
                                <button
                                    className='infos-consultant-page-button'
                                    onClick={() => {
                                        setIsActive(false);
                                        patchConsultant();
                                    }}
                                >
                                    Archiver ce consultant
                                </button>
                        )}
                        {!currentConsultant.is_active
                        && (
                                <button
                                    className='infos-consultant-page-button'
                                    onClick={() => {
                                        setIsActive(true);
                                        patchConsultant();
                                    }}
                                >
                                    Rendre ce consultant actif
                                </button>
                        )}
                    </Link>
                    <DocumentsToCheck currentConsultant={currentConsultant} />
                    <ConsultantFormInfos />
                    <ReportsList />
                    <div className="infos-consultant-page-buttons-container">
                        <Link to={(isNewReportClientReadOnly
                                && isNewReportCommentReadOnly
                                && isCurrentReportClientReadOnly
                                && isCurrentReportCommentReadOnly) ? `/consultants` : '' }>
                            <button
                                className="infos-consultant-page-button"
                                onClick={() => {
                                    if (isNewReportClientReadOnly
                                    && isNewReportCommentReadOnly
                                    && isCurrentReportClientReadOnly
                                    && isCurrentReportCommentReadOnly) {
                                        patchConsultant();
                                    } else {
                                        setIsAlertDivOpen(true);
                                    }
                                }} 
                            >
                                Valider
                            </button>
                        </Link>
                    </div>
                    {isAlertDivOpen
                    && (
                        <div className='meeting-consultant-page-modal-error'>
                            <p style={{ fontWeight: 'bold' }}>Attention ! Certaines modifications n&apos;ont pas été validées.</p>
                            <button
                                onClick={() => {setIsAlertDivOpen(false);}}
                            >
                                Annuler
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    )
};

InfosConsultantPage.propTypes = {
    loadConsultant: PropTypes.func.isRequired,
    loadConsultantsInCharge: PropTypes.func.isRequired,
    patchConsultant: PropTypes.func.isRequired,
    // patchReport: PropTypes.func.isRequired,
    isConsultantPageLoading: PropTypes.bool.isRequired,
    setIsConsultantPageLoading: PropTypes.func.isRequired,
    setIsActive: PropTypes.func.isRequired,
    currentConsultant: PropTypes.object.isRequired,
    isCurrentReportClientReadOnly: PropTypes.bool.isRequired,
    isCurrentReportCommentReadOnly: PropTypes.bool.isRequired,
    isNewReportClientReadOnly: PropTypes.bool.isRequired,
    isNewReportCommentReadOnly: PropTypes.bool.isRequired,
    setIsCurrentReportClientReadOnly: PropTypes.func.isRequired,
    setIsCurrentReportCommentReadOnly: PropTypes.func.isRequired,
    setIsNewReportClientReadOnly: PropTypes.func.isRequired,
    setIsNewReportCommentReadOnly: PropTypes.func.isRequired,
};

export default InfosConsultantPage;