import {
  FILTER_SEARCHED_WEEKS,
  HANDLE_SEARCH,
  SAVE_CONSULTANT_WEEKS,
  SET_ARE_CURRENT_CONSULTANT_REPORTS_LOADING,
  SET_CURRENT_WEEK,
  SET_DEFAULT_DISPLAYED_WEEK,
} from '../actions/weekActions';


const initialState = {
    // ici le state initial
    search: '',
    currentWeek: 0,
    currentConsultantReportsWeeks: [],
    searchedWeeks: [],
    areCurrentConsultantReportsLoading: true,
  };

  
function weekReducer(state = initialState, action) {
  switch (action.type) {
    case HANDLE_SEARCH:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        search: action.value,
      }
    case SAVE_CONSULTANT_WEEKS: {
      let newWeeksArray = [];
      (action.value).forEach((report) => {
        newWeeksArray.push({...report.week})
      });
      newWeeksArray.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        currentConsultantReportsWeeks: newWeeksArray,
        areCurrentConsultantReportsLoading: false,
      }}
    case FILTER_SEARCHED_WEEKS: {
      let newSearchedWeeksArray = [];
      state.currentConsultantReportsWeeks.forEach((week) => {
        if ((week.number + ' ').includes(action.value)) {
          newSearchedWeeksArray.push({...week})
        }
      })
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        searchedWeeks: newSearchedWeeksArray,
      }}
    case SET_ARE_CURRENT_CONSULTANT_REPORTS_LOADING: {
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        areCurrentConsultantReportsLoading: action.value,
      }}
    case SET_DEFAULT_DISPLAYED_WEEK: {
      let newCurrentWeek = 0;
      let newSearch = '';
      if (state.currentConsultantReportsWeeks.length !== 0) {
        newCurrentWeek = state.currentConsultantReportsWeeks[0].id
        newSearch = state.currentConsultantReportsWeeks[0].number
      }
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        currentWeek: newCurrentWeek,
        search: newSearch,
      }}
    case SET_CURRENT_WEEK:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        currentWeek: action.value,
      };
    default:
      return state;
  }
}
  
export default weekReducer;