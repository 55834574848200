import { connect } from 'react-redux';
import { setIsMeetingConsultantPageLoading } from '../../../../actions/meetingActions';
import { setIsDocumentsListLoading } from '../../../../actions/documentActions';

// on importe le composant de présentation
import MeetingListTable from '../../../../components/MeetingPage/MeetingList/MeetingListTable';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  isMeetingConsultantsListLoading: state.meetingReducer.isMeetingConsultantsListLoading,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  setIsMeetingConsultantPageLoading: (newValue) => {
    dispatch(setIsMeetingConsultantPageLoading(newValue));
  },
  setIsDocumentsListLoading: (newValue) => {
    dispatch(setIsDocumentsListLoading(newValue));
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(MeetingListTable);