// === action types
export const FETCH_CONSULTANT = 'FETCH_CONSULTANT';
export const FETCH_CONSULTANTS_LIST = 'FETCH_CONSULTANTS_LIST';
export const SAVE_CONSULTANT = 'SAVE_CONSULTANT';
export const ARCHIVE_CONSULTANT = 'ARCHIVE_CONSULTANT';
export const DELETE_CONSULTANT = 'DELETE_CONSULTANT';
export const PATCH_CONSULTANT = 'PATCH_CONSULTANT';
export const POST_NEW_CONSULTANT = 'POST_NEW_CONSULTANT';
export const RESET_NEW_CONSULTANT = 'RESET_NEW_CONSULTANT';
export const FETCH_CONSULTANTS_IN_CHARGE = 'FETCH_CONSULTANTS_IN_CHARGE';
export const SAVE_CONSULTANTS_IN_CHARGE = 'SAVE_CONSULTANTS_IN_CHARGE';
export const SAVE_CREALIS_CONSULTANTS_ARRAY = 'SAVE_CREALIS_CONSULTANTS_ARRAY';
export const SAVE_PORTSIDE_CONSULTANTS_ARRAY = 'SAVE_PORTSIDE_CONSULTANTS_ARRAY';
export const SAVE_HCS_CONSULTANTS_ARRAY = 'SAVE_HCS_CONSULTANTS_ARRAY';
export const SET_FIRSTNAME = 'SET_FIRSTNAME';
export const SET_LASTNAME = 'SET_LASTNAME';
export const SET_STATUS = 'SET_STATUS';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_ARRIVAL_DATE = 'SET_ARRIVAL_DATE';
export const SET_DOES_ACTION = 'SET_DOES_ACTION';
export const SET_NEW_FIRSTNAME = 'SET_NEW_FIRSTNAME';
export const SET_NEW_LASTNAME = 'SET_NEW_LASTNAME';
export const SET_NEW_STATUS = 'SET_NEW_STATUS';
export const SET_NEW_COMPANY = 'SET_NEW_COMPANY';
export const SET_NEW_ARRIVAL_DATE = 'SET_NEW_ARRIVAL_DATE';
export const SET_NEW_DOES_ACTION = 'SET_NEW_DOES_ACTION';
export const SET_HAS_NEW_CONSULTANT_BEEN_CREATED = 'SET_HAS_NEW_CONSULTANT_BEEN_CREATED';
export const SET_IS_ACTIVE = 'SET_IS_ACTIVE';
export const SET_IS_CONSULTANTS_LIST_LOADING = 'SET_IS_CONSULTANTS_LIST_LOADING';
export const SET_IS_CONSULTANT_PAGE_LOADING = 'SET_IS_CONSULTANT_PAGE_LOADING';
export const SET_IS_CONSULTANT_REPORT_LOADING = 'SET_IS_CONSULTANT_REPORT_LOADING';
export const SET_IS_CONSULTANTS_IN_CHARGE_LIST_LOADING = 'SET_IS_CONSULTANTS_IN_CHARGE_LIST_LOADING';

// === action creators
export const fetchConsultantsList = (companyValue) => ({
  type: FETCH_CONSULTANTS_LIST,
  companyValue: companyValue,
});

export const fetchConsultant = (consultantId) => ({
  type: FETCH_CONSULTANT,
  value: consultantId,
});

export const saveConsultant = (consultantData) => ({
  type: SAVE_CONSULTANT,
  value: consultantData,
});

export const archiveConsultant = (consultantId, newValue) => ({
  type: ARCHIVE_CONSULTANT,
  id: consultantId,
  value: newValue,
});

export const deleteConsultant = (consultantId, consultantCompany) => ({
  type: DELETE_CONSULTANT,
  id: consultantId,
  company: consultantCompany,
});

export const patchConsultant = () => ({
  type: PATCH_CONSULTANT,
});

export const resetNewConsultant = () => ({
  type: RESET_NEW_CONSULTANT,
});

export const postNewConsultant = () => ({
  type: POST_NEW_CONSULTANT,
});

export const fetchConsultantsInCharge = () => ({
  type: FETCH_CONSULTANTS_IN_CHARGE,
});

export const saveCrealisConsultantsArray = (crealisConsultantsArray) => ({
  type: SAVE_CREALIS_CONSULTANTS_ARRAY,
  value: crealisConsultantsArray,
});

export const savePortsideConsultantsArray = (portsideConsultantsArray) => ({
  type: SAVE_PORTSIDE_CONSULTANTS_ARRAY,
  value: portsideConsultantsArray,
});

export const saveHCSConsultantsArray = (hcsConsultantsArray) => ({
  type: SAVE_HCS_CONSULTANTS_ARRAY,
  value: hcsConsultantsArray,
});

export const saveConsultantsInCharge = (consultantsInChargeArray) => ({
  type: SAVE_CONSULTANTS_IN_CHARGE,
  value: consultantsInChargeArray,
});

export const setFirstname = (newValue) => ({
  type: SET_FIRSTNAME,
  value: newValue,
});

export const setLastname = (newValue) => ({
  type: SET_LASTNAME,
  value: newValue,
});

export const setStatus = (newValue) => ({
  type: SET_STATUS,
  value: newValue,
});

export const setCompany = (newValue) => ({
  type: SET_COMPANY,
  value: newValue,
});

export const setArrivalDate = (newValue) => ({
  type: SET_ARRIVAL_DATE,
  value: newValue,
});

export const setDoesAction = (newValue) => ({
  type: SET_DOES_ACTION,
  value: newValue,
});

export const setIsActive = (newValue) => ({
  type: SET_IS_ACTIVE,
  value: newValue,
});

export const setHasNewConsultantBeenCreated = (newValue) => ({
  type: SET_HAS_NEW_CONSULTANT_BEEN_CREATED,
  value: newValue,
});

export const setIsConsultantsListLoading = (newValue) => ({
  type: SET_IS_CONSULTANTS_LIST_LOADING,
  value: newValue,
});

export const setIsConsultantPageLoading = (newValue) => ({
  type: SET_IS_CONSULTANT_PAGE_LOADING,
  value: newValue,
});

export const setIsConsultantReportLoading = (newValue) => ({
  type: SET_IS_CONSULTANT_REPORT_LOADING,
  value: newValue,
});

export const setIsConsultantsInChargeListLoading = (newValue) => ({
  type: SET_IS_CONSULTANTS_IN_CHARGE_LIST_LOADING,
  value: newValue,
});

// Actions for InfosConsultantForm

export const setNewFirstname = (newValue) => ({
  type: SET_NEW_FIRSTNAME,
  value: newValue,
});

export const setNewLastname = (newValue) => ({
  type: SET_NEW_LASTNAME,
  value: newValue,
});

export const setNewStatus = (newValue) => ({
  type: SET_NEW_STATUS,
  value: newValue,
});

export const setNewCompany = (newValue) => ({
  type: SET_NEW_COMPANY,
  value: newValue,
});

export const setNewArrivalDate = (newValue) => ({
  type: SET_NEW_ARRIVAL_DATE,
  value: newValue,
});

export const setNewDoesAction = (newValue) => ({
  type: SET_NEW_DOES_ACTION,
  value: newValue,
});
