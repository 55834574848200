import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './styles.scss';

const ConnexionPage = ({
    login,
    password,
    setLogin,
    setPassword,
    setIsConnexionLoading,
    submitLogin,
    isAuthentified,
}) => {
    const isError = false;

    return (
        <div className="connexion-page-container">
            {isAuthentified
            && <h2>Vous êtes déjà connecté.</h2>}
            {!isAuthentified
            && (
                <>
                    <h2 className="connexion-page-title">Connexion</h2>

                    {isError
                    && (
                        <div className='connexion-page-error-container'>
                            <p>Il y a une erreur.</p>
                        </div>
                    )}

                    <div className="connexion-page-form-container">
                        {/* FORMULAIRE DE CONNEXION */}
                        <form className="connexion-page-form" action="" method="post" />
                        <div className='connexion-page-form-item'>
                            <label className='connexion-page-form-label'>Identifiant</label>
                            <input type="text" name="login" value={login} onChange={(event) => {setLogin(event.target.value)}} className='connexion-page-form-input' />
                        </div>
                        <div className='connexion-page-form-item'>
                            <label className='connexion-page-form-label'>Mot de passe</label>
                            <input type="password" name="password" value={password} onChange={(event) => {setPassword(event.target.value)}} className='connexion-page-form-input' />
                        </div>
                        {/* VALIDATION DU FORMUALIRE */}
                        <Link to="/consultants">
                            <button
                                type="submit"
                                className="connexion-page-form-button"
                                onClick={() => {
                                    setIsConnexionLoading(true);
                                    submitLogin();
                                }}
                            >
                                Valider
                            </button>
                        </Link>
                    </div>
                </>
            )}
        </div>
    );
};

ConnexionPage.propTypes = {
    login: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    setLogin: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired,
    setIsConnexionLoading: PropTypes.func.isRequired,
    submitLogin: PropTypes.func.isRequired,
    isAuthentified: PropTypes.bool.isRequired,
};


// ConnexionPage.defaultProps = {
// };

export default ConnexionPage;