import { connect } from 'react-redux';
import { setNewArrivalDate, setNewFirstname, setNewLastname } from '../../../actions/consultantActions';

// on importe le composant de présentation
import ConsultantHeaderInfos from '../../../components/InfosConsultantForm/ConsultantHeaderInfos';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  newConsultant: state.consultantReducer.newConsultant,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  handleNewArrivalDateChange: (newValue) => {
    dispatch(setNewArrivalDate(newValue));
  },
  handleNewLastnameChange: (newValue) => {
    dispatch(setNewLastname(newValue));
  },
  handleNewFirstnameChange: (newValue) => {
    dispatch(setNewFirstname(newValue));
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantHeaderInfos);