import { connect } from 'react-redux';
import {
  setIsMeetingConsultantPageLoading,
  fetchMeetingConsultant,
  validateReportStatus,
} from '../../actions/meetingActions';
import {
  resetCurrentReport,
  resetNewReport,
  setIsCurrentReportClientReadOnly,
  setIsCurrentReportCommentReadOnly,
  setIsNewReportClientReadOnly,
  setIsNewReportCommentReadOnly
} from '../../actions/reportActions';
import { patchConsultant, setIsActive, fetchConsultantsInCharge } from '../../actions/consultantActions';

// on importe le composant de présentation
import MeetingConsultantPage from '../../components/MeetingConsultantPage';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  isMeetingConsultantPageLoading: state.meetingReducer.isMeetingConsultantPageLoading,
  currentConsultant: state.consultantReducer.currentConsultant,
  currentReport: state.consultantReducer.currentReport,
  meetingConsultantsArray: state.meetingReducer.meetingConsultantsArray,
  currentMeetingWeek: state.meetingReducer.currentMeetingWeek,
  areNewReportsCreated: state.meetingReducer.areNewReportsCreated,
  isCurrentReportClientReadOnly: state.consultantReducer.isCurrentReportClientReadOnly,
  isCurrentReportCommentReadOnly: state.consultantReducer.isCurrentReportCommentReadOnly,
  isNewReportClientReadOnly: state.consultantReducer.isNewReportClientReadOnly,
  isNewReportCommentReadOnly: state.consultantReducer.isNewReportCommentReadOnly,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  loadMeetingConsultant: (meetingConsultantId) => {
    dispatch(fetchMeetingConsultant(meetingConsultantId));
  },
  patchConsultant: () => {
    dispatch(patchConsultant());
  },
  setIsMeetingConsultantPageLoading: (newValue) => {
    dispatch(setIsMeetingConsultantPageLoading(newValue));
  },
  resetCurrentReport: () => {
    dispatch(resetCurrentReport());
  },
  resetNewReport: () => {
    dispatch(resetNewReport());
  },
  setIsActive: (newValue) => {
    dispatch(setIsActive(newValue));
  },
  validateReportStatus: (meetingConsultantId) => {
    dispatch(validateReportStatus(meetingConsultantId));
  },
  loadConsultantsInCharge: () => {
    dispatch(fetchConsultantsInCharge());
  },
  setIsCurrentReportClientReadOnly: (newValue) => {
    dispatch(setIsCurrentReportClientReadOnly(newValue));
  },
  setIsCurrentReportCommentReadOnly: (newValue) => {
    dispatch(setIsCurrentReportCommentReadOnly(newValue));
  },
  setIsNewReportClientReadOnly: (newValue) => {
    dispatch(setIsNewReportClientReadOnly(newValue));
  },
  setIsNewReportCommentReadOnly: (newValue) => {
    dispatch(setIsNewReportCommentReadOnly(newValue));
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(MeetingConsultantPage);