import axios from "axios";
import { bake_cookie } from 'sfcookies';

// import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';

import {
    setIsConnexionLoading,
    setLogin,
    setPassword,
    SUBMIT_LOGIN,
    successLogin,
} from "../actions/connexionActions";

// const baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
// const baseUrl = 'http://localhost:8000/'

const connexionMiddleware = (store) => (next) => (action) => {

    const currentEnv = store.getState().connexionReducer.appenv;
    let baseUrl = '';
    if (currentEnv === "prod") {
      baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
    } else {
      baseUrl = 'http://localhost:8000/'
    }

    switch (action.type) {
        case SUBMIT_LOGIN: {
            const userToPost = {
                username: store.getState().connexionReducer.login,
                password: store.getState().connexionReducer.password,
            }
            axios.post(
                // URL
                `${baseUrl}api/login`,
                userToPost,
            )
                .then((response) => {
                    store.dispatch(successLogin());
                    bake_cookie('jwt', response.data.token)
                    // document.cookie = `token=${response.data.token}`
                    // localStorage.setItem('token', response.data.token);

                })
                .then(() => {
                    store.dispatch(setIsConnexionLoading(false));
                    store.dispatch(setLogin(''));
                    store.dispatch(setPassword(''));
                });
            break;
        }
        default:
    }

    // on passe l'action au suivant (middleware suivant ou reducer)
    next(action);
};

export default connexionMiddleware;