// === action types
export const HANDLE_SEARCH = 'HANDLE_SEARCH';
export const FILTER_SEARCHED_WEEKS = 'FILTER_SEARCHED_WEEKS';
export const SAVE_CONSULTANT_WEEKS = 'SAVE_CONSULTANT_WEEKS';
export const SET_ARE_CURRENT_CONSULTANT_REPORTS_LOADING = 'SET_ARE_CURRENT_CONSULTANT_REPORTS_LOADING';
export const SET_CURRENT_WEEK = 'SET_CURRENT_WEEK';
export const SET_DEFAULT_DISPLAYED_WEEK = 'SET_DEFAULT_DISPLAYED_WEEK';

// === action creators
export const handleSearch = (newValue) => ({
  type: HANDLE_SEARCH,
  value: newValue,
});

export const filterSearchedWeeks = (value) => ({
  type: FILTER_SEARCHED_WEEKS,
  value: value,
});

export const saveConsultantWeeks = (reportsArray) => ({
  type: SAVE_CONSULTANT_WEEKS,
  value: reportsArray,
});

export const setAreCurrentConsultantReportsLoading = (newValue) => ({
  type: SET_ARE_CURRENT_CONSULTANT_REPORTS_LOADING,
  value: newValue,
});

export const setCurrentWeek = (weekId) => ({
  type: SET_CURRENT_WEEK,
  value: weekId,
});

export const setDefaultDisplayedWeek = () => ({
  type: SET_DEFAULT_DISPLAYED_WEEK,
});
