import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import ConsultantsListTableHead from './ConsultantsListTableHead';
import Loader from '../../../Loader';

import { getComparator } from './table-functions';
import { getColumnForRequest, getCompany } from '../../../../scripts/functions';

import './styles.scss'

const ConsultantsListTable = ({
  archiveConsultant,
  deleteConsultant,
  consultantsList,
  crealisDocumentsList,
  crealisPortsideDocumentsList,
  portsideDocumentsList,
  hcsDocumentsList,
  loadDocumentsList,
  isConsultantsListLoading,
  setIsConsultantPageLoading,
  setIsDocumentsListLoading,
  isActiveConsultants,
  isInactiveConsultants,
  companyValue,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('lastname');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isConfirmationDivOpen, setIsConfirmationDivOpen] = useState(false);
  const [consultantToDeleteId, setConsultantToDeleteId] = useState(0);
  const [consultantToDeleteCompany, setConsultantToDeleteCompany] = useState(0);
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - consultantsList.length) : 0;

  const columnForRequest = getColumnForRequest(companyValue);

  useEffect(() => {
    loadDocumentsList(columnForRequest, companyValue);
    loadDocumentsList('is_crealisportside_doc', 2);
  }, []);

  return (
    <div>
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
            {isConsultantsListLoading
              && (
                <Loader />
              )}
            {!isConsultantsListLoading
              && (
                <>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'small'}
                  >
                    <ConsultantsListTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={consultantsList.length}
                    />
                  <TableBody>
                    {consultantsList.slice().sort(getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((consultant, index) => {
                        const labelId = `consultants-list-table-row-${index}`;
                        const consultantCompany = getCompany(+consultant.company);
                        // filter checkedDocuments by consultant's company to count them and display this count in a progress bar
                        let newCheckedDocumentsArray = [];
                        let numberOfDocuments = 0;
                        switch (consultantCompany) {
                            case "Créalis Ingénierie":
                              newCheckedDocumentsArray = consultant.checkedDocuments.filter(checkedDocument => checkedDocument.document.is_crealis_doc === true);
                              numberOfDocuments = crealisDocumentsList.length;
                              break;
                            case "Portside":
                              newCheckedDocumentsArray = consultant.checkedDocuments.filter(checkedDocument => checkedDocument.document.is_portside_doc === true);
                              numberOfDocuments = portsideDocumentsList.length;
                              break;
                            case "HCS Groupe":
                              newCheckedDocumentsArray = consultant.checkedDocuments.filter(checkedDocument => checkedDocument.document.is_hcs_doc === true);
                              numberOfDocuments = hcsDocumentsList.length;
                              break;
                            case "Créalis Ingénierie/Portside":
                              newCheckedDocumentsArray = consultant.checkedDocuments.filter(checkedDocument => checkedDocument.document.is_crealisportside_doc === true);
                              numberOfDocuments = crealisPortsideDocumentsList.length;
                              break;
                            default:
                              break;
                            }
                        const countCheckedDocuments = newCheckedDocumentsArray.length;
                        const calculateProgressBarWidth = countCheckedDocuments / numberOfDocuments * 100;

                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="normal"
                            >
                              <p className="consultants-list-table-cell-content">
                                <Link
                                  to={`/consultants/${consultant.id}`}
                                  onClick={() => {
                                    setIsConsultantPageLoading(true);
                                    setIsDocumentsListLoading(true);
                                  }}
                                >
                                  {consultant.lastname.toUpperCase()} {consultant.firstname}
                                </Link>
                              </p>
                            </TableCell>
                            <TableCell align="left">
                              <p className="consultants-list-table-cell-content">
                                {consultant.status}
                              </p>
                            </TableCell>
                            <TableCell align="left">
                              <p className="consultants-list-table-cell-content">
                                {consultantCompany}
                              </p>
                            </TableCell>
                            <TableCell align="left">
                              <div className="consultants-list-table-documents-container">
                                <div className="stat_container">
                                  <div className="bar_value" style={{ "width": calculateProgressBarWidth + "%", "background": countCheckedDocuments == numberOfDocuments ? "#007900" : "#900" }}></div>
                                </div>
                                <p className="consultants-list-table-cell-content">
                                  {countCheckedDocuments} sur {numberOfDocuments}
                                </p>
                              </div>
                            </TableCell>
                            {isActiveConsultants
                              &&
                              (
                                <TableCell align="center" sx={{ width: 100 }}>
                                  <ArchiveTwoToneIcon
                                    onClick={() => {
                                        archiveConsultant(consultant.id, false);
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                  />
                                </TableCell>
                              )
                            }
                            {isInactiveConsultants
                              &&
                              (
                                <TableCell align="center" sx={{ width: 100 }}>
                                  <UndoIcon
                                    onClick={() => {
                                        archiveConsultant(consultant.id, true);
                                    }}
                                    sx={{ mr: '0.3em', cursor: 'pointer' }}
                                  />
                                  <DeleteTwoToneIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setIsConfirmationDivOpen(true);
                                        setConsultantToDeleteId(consultant.id)
                                        setConsultantToDeleteCompany(consultant.company)
                                    }}
                                  />
                                </TableCell>
                              )
                            }
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (33) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  </Table>
                </TableContainer>
                </>
              )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={consultantsList.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Consultants par page :"
          labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} sur ${count !== -1 ? count : `more than ${to}`}`; }}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
    {isConfirmationDivOpen
    && (
      <div className="consultants-list-table-delete-confirmation">
        <p className="consultants-list-table-delete-confirmation-text">Supprimer définitivement ce consultant ?</p>
        <div className="consultants-list-table-delete-confirmation-buttons-div">
        <button
          className="consultants-list-table-delete-confirmation-button"
          onClick={() => {
            deleteConsultant(consultantToDeleteId, consultantToDeleteCompany);
            setIsConfirmationDivOpen(false);
          }}
        >
          Oui
        </button>
        <button
          className="consultants-list-table-delete-confirmation-button"
          onClick={() => {
            setIsConfirmationDivOpen(false);
          }}
        >
          Annuler
        </button>
      </div>
      </div>
    )}
    </div>
  );
};

ConsultantsListTable.propTypes = {
  consultantsList: PropTypes.array.isRequired,
  isActiveConsultants: PropTypes.bool,
  isInactiveConsultants: PropTypes.bool,
  isConsultantsListLoading: PropTypes.bool.isRequired,
  setIsConsultantPageLoading: PropTypes.func.isRequired,
  setIsDocumentsListLoading: PropTypes.func.isRequired,
  archiveConsultant: PropTypes.func.isRequired,
  deleteConsultant: PropTypes.func.isRequired,
  companyValue: PropTypes.number.isRequired,
  loadDocumentsList: PropTypes.func.isRequired,
  crealisDocumentsList: PropTypes.array.isRequired,
  crealisPortsideDocumentsList: PropTypes.array.isRequired,
  portsideDocumentsList: PropTypes.array.isRequired,
  hcsDocumentsList: PropTypes.array.isRequired,
};

ConsultantsListTable.defaultProps = {
  isActiveConsultants: false,
  isInactiveConsultants: false,
};

export default ConsultantsListTable;