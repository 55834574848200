// === action types
export const ADD_NEW_ACTION = 'ADD_NEW_ACTION';
export const ADD_NEW_CLIENT = 'ADD_NEW_CLIENT';
export const ADD_NEW_REPORT_NEW_ACTION = 'ADD_NEW_REPORT_NEW_ACTION';
export const ADD_NEW_REPORT_NEW_CLIENT = 'ADD_NEW_REPORT_NEW_CLIENT';
export const DELETE_ACTION = 'DELETE_ACTION';
export const DELETE_DB_ACTION = 'DELETE_DB_ACTION';
export const DELETE_NEW_REPORT_ACTION = 'DELETE_NEW_REPORT_ACTION';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_DB_CLIENT = 'DELETE_DB_CLIENT';
export const DELETE_NEW_REPORT_CLIENT = 'DELETE_NEW_REPORT_CLIENT';
export const INIT_NEW_REPORT_WITH_CLIENTS = 'INIT_NEW_REPORT_WITH_CLIENTS';
export const PATCH_ACTION = 'PATCH_ACTION';
export const PATCH_CLIENT = 'PATCH_CLIENT';
export const PATCH_COMMENT = 'PATCH_COMMENT';
export const POST_ACTION_TO_NEW_REPORT = 'POST_ACTION_TO_NEW_REPORT';
export const POST_CLIENT_TO_NEW_REPORT = 'POST_CLIENT_TO_NEW_REPORT';
export const POST_NEW_ACTION = 'POST_NEW_ACTION';
export const POST_NEW_CLIENT = 'POST_NEW_CLIENT';
export const POST_NEW_REPORT = 'POST_NEW_REPORT';
export const RESET_CURRENT_REPORT = 'RESET_CURRENT_REPORT';
export const RESET_NEW_REPORT = 'RESET_NEW_REPORT';
export const SET_CLIENT_DATE = 'SET_CLIENT_DATE';
export const SET_CLIENT_NAME = 'SET_CLIENT_NAME';
export const SET_COMMENT = 'SET_COMMENT';
export const SET_CURRENT_REPORT = 'SET_CURRENT_REPORT';
export const SET_IS_ACTIONS_LIST_LOADING = 'SET_IS_ACTIONS_LIST_LOADING';
export const SET_IS_IMPORTANT = 'SET_IS_IMPORTANT';
export const SET_NEW_VALUE_ACTION = 'SET_NEW_VALUE_ACTION';
export const SET_NEW_REPORT = 'SET_NEW_REPORT';
export const SET_NEW_REPORT_CLIENT_DATE = 'SET_NEW_REPORT_CLIENT_DATE';
export const SET_NEW_REPORT_CLIENT_NAME = 'SET_NEW_REPORT_CLIENT_NAME';
export const SET_NEW_REPORT_COMMENT = 'SET_NEW_REPORT_COMMENT';
export const SET_NEW_REPORT_IS_IMPORTANT = 'SET_NEW_REPORT_IS_IMPORTANT';
export const SET_NEW_REPORT_NEW_VALUE_ACTION = 'SET_NEW_REPORT_NEW_VALUE_ACTION';
export const SET_IS_CURRENT_REPORT_CLIENT_READONLY = 'SET_IS_CURRENT_REPORT_CLIENT_READONLY';
export const SET_IS_CURRENT_REPORT_COMMENT_READONLY = 'SET_IS_CURRENT_REPORT_COMMENT_READONLY';
export const SET_IS_NEW_REPORT_CLIENT_READONLY = 'SET_IS_NEW_REPORT_CLIENT_READONLY';
export const SET_IS_NEW_REPORT_COMMENT_READONLY = 'SET_IS_NEW_REPORT_COMMENT_READONLY';
export const DELETE_REPORT = 'DELETE_REPORT';

// === action creators
export const addNewAction = (newAction) => ({
  type: ADD_NEW_ACTION,
  value: newAction,
});

export const addNewClient = (newClient) => ({
  type: ADD_NEW_CLIENT,
  value: newClient,
});

export const addNewReportNewClient = (newClient) => ({
  type: ADD_NEW_REPORT_NEW_CLIENT,
  value: newClient,
});

export const addNewReportNewAction = (newAction) => ({
  type: ADD_NEW_REPORT_NEW_ACTION,
  value: newAction,
});

export const deleteAction = (actionId) => ({
  type: DELETE_ACTION,
  id: actionId,
});

export const deleteDbAction = (actionId, isNewReport) => ({
  type: DELETE_DB_ACTION,
  id: actionId,
  isNewReport: isNewReport,
});

export const deleteClient = (clientId) => ({
  type: DELETE_CLIENT,
  id: clientId,
});

export const deleteDbClient = (clientId, isNewReport) => ({
  type: DELETE_DB_CLIENT,
  id: clientId,
  isNewReport: isNewReport,
});

export const deleteNewReportClient = (clientId) => ({
  type: DELETE_NEW_REPORT_CLIENT,
  id: clientId,
});

export const deleteNewReportAction = (actionId) => ({
  type: DELETE_NEW_REPORT_ACTION,
  id: actionId,
});

export const initNewReportWithClients = (clientsArray) => ({
  type: INIT_NEW_REPORT_WITH_CLIENTS,
  value: clientsArray,
});

export const patchAction = (actionId, propertyToUpdate, newValue, isNewReport) => ({
  type: PATCH_ACTION,
  id: actionId,
  property: propertyToUpdate,
  value: newValue,
  isNewReport: isNewReport,
});

export const patchClient = (clientId, newClientName, newClientDate) => ({
  type: PATCH_CLIENT,
  id: clientId,
  name: newClientName,
  date: newClientDate,
});

export const patchComment = (reportId, newComment) => ({
  type: PATCH_COMMENT,
  id: reportId,
  value: newComment,
});

export const postActionToNewReport = (reportId, actionToAdd) => ({
  type: POST_ACTION_TO_NEW_REPORT,
  id: reportId,
  value: actionToAdd,
});

export const postClientToNewReport = (reportId, clientToAdd) => ({
  type: POST_CLIENT_TO_NEW_REPORT,
  id: reportId,
  value: clientToAdd,
});

export const postNewAction = (reportId, isNewReport) => ({
  type: POST_NEW_ACTION,
  id: reportId,
  isNewReport: isNewReport,
});

export const postNewClient = (reportId, isNewReport) => ({
  type: POST_NEW_CLIENT,
  id: reportId,
  isNewReport: isNewReport,
});

export const postNewReport = (consultantId) => ({
  type: POST_NEW_REPORT,
  id: consultantId,
});

export const resetCurrentReport = () => ({
  type: RESET_CURRENT_REPORT,
});

export const resetNewReport = () => ({
  type: RESET_NEW_REPORT,
});

export const setClientName = (newClientName, clientId) => ({
  type: SET_CLIENT_NAME,
  value: newClientName,
  id: clientId,
});

export const setClientDate = (newClientDate, clientId) => ({
  type: SET_CLIENT_DATE,
  value: newClientDate,
  id: clientId,
});

export const setComment = (newComment) => ({
  type: SET_COMMENT,
  value: newComment,
});

export const setNewReportClientName = (newClientName, clientId) => ({
  type: SET_NEW_REPORT_CLIENT_NAME,
  value: newClientName,
  id: clientId,
});

export const setNewReportClientDate = (newClientDate, clientId) => ({
  type: SET_NEW_REPORT_CLIENT_DATE,
  value: newClientDate,
  id: clientId,
});

export const setNewReportComment = (newComment) => ({
  type: SET_NEW_REPORT_COMMENT,
  value: newComment,
});

export const setCurrentReport = (weekId) => ({
  type: SET_CURRENT_REPORT,
  value: weekId,
});

export const setIsActionsListLoading = (newValue) => ({
  type: SET_IS_ACTIONS_LIST_LOADING,
  value: newValue,
});

export const setIsImportant = (actionId, newValue) => ({
  type: SET_IS_IMPORTANT,
  id: actionId,
  value: newValue,
});

export const setNewReport = (weekId) => ({
  type: SET_NEW_REPORT,
  value: weekId,
});

export const setNewReportIsImportant = (actionId, newValue) => ({
  type: SET_NEW_REPORT_IS_IMPORTANT,
  id: actionId,
  value: newValue,
});

export const setNewReportNewValueAction = (actionId, newAction) => ({
  type: SET_NEW_REPORT_NEW_VALUE_ACTION,
  id: actionId,
  value: newAction,
});

export const setNewValueAction = (actionId, newAction) => ({
  type: SET_NEW_VALUE_ACTION,
  id: actionId,
  value: newAction,
});

export const setIsCurrentReportClientReadOnly = (newValue) => ({
  type: SET_IS_CURRENT_REPORT_CLIENT_READONLY,
  value: newValue,
});

export const setIsCurrentReportCommentReadOnly = (newValue) => ({
  type: SET_IS_CURRENT_REPORT_COMMENT_READONLY,
  value: newValue,
});

export const setIsNewReportClientReadOnly = (newValue) => ({
  type: SET_IS_NEW_REPORT_CLIENT_READONLY,
  value: newValue,
});

export const setIsNewReportCommentReadOnly = (newValue) => ({
  type: SET_IS_NEW_REPORT_COMMENT_READONLY,
  value: newValue,
});

export const deleteReport = (reportId) => ({
  type: DELETE_REPORT,
  id: reportId,
});