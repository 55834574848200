import { combineReducers } from 'redux';

import consultantReducer from './consultantReducer';
import documentReducer from './documentReducer';
import weekReducer from './weekReducer';
import meetingReducer from './meetingReducer';
import connexionReducer from './connexionReducer';

const rootReducer = combineReducers({
  consultantReducer: consultantReducer,
  documentReducer: documentReducer,
  weekReducer: weekReducer,
  meetingReducer: meetingReducer,
  connexionReducer: connexionReducer,
});

export default rootReducer;
