import { connect } from 'react-redux';
import { handleSearch, filterSearchedWeeks, setCurrentWeek, setAreCurrentConsultantReportsLoading } from '../../actions/weekActions';

// on importe le composant de présentation
import ReportsList from '../../components/ReportsList';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  search: state.weekReducer.search,
  currentWeek: state.weekReducer.currentWeek,
  currentConsultant: state.consultantReducer.currentConsultant,
  searchedWeeks: state.weekReducer.searchedWeeks,
  currentConsultantReportsWeeks: state.weekReducer.currentConsultantReportsWeeks,
  areCurrentConsultantReportsLoading: state.weekReducer.areCurrentConsultantReportsLoading,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  handleSearch: (newValue) => {
    dispatch(handleSearch(newValue));
  },
  filterSearchedWeeks: (weekNumber) => {
    dispatch(filterSearchedWeeks(weekNumber));
  },
  setCurrentWeek: (weekId) => {
    dispatch(setCurrentWeek(weekId));
  },
  setAreCurrentConsultantReportsLoading: (newValue) => {
    dispatch(setAreCurrentConsultantReportsLoading(newValue));
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(ReportsList);