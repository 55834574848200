import PropTypes from 'prop-types';

import { formatDate } from '../../../scripts/functions';

import './styles.scss';

const ConsultantHeaderInfos = ({ 
    newConsultant,
    handleNewArrivalDateChange,
    handleNewLastnameChange,
    handleNewFirstnameChange,
}) => {
    return (
        <div className="consultant-header-infos-container">
           
                <h1 className="infos-consultant-page-name infos-consultant-page-lastname">
                    <input 
                        type="text" 
                        value={newConsultant.lastname}
                        placeholder="NOM"
                        onChange={(event) => handleNewLastnameChange(event.target.value)} 
                        className="infos-consultant-input-name infos-consultant-input-lastname" 
                        name="lastname" 
                    />
                </h1>
            
                    <h1 className="infos-consultant-page-name">
                    <input 
                        type="text" 
                        value={newConsultant.firstname} 
                        placeholder="Prénom"
                        onChange={(event) => handleNewFirstnameChange(event.target.value)} 
                        className="infos-consultant-input-name infos-consultant-input-firstname" 
                        name="firstname" 
                    />
                    </h1>
            <div className='infos-consultant-page-infos'>
                <p className='infos-consultant-page-info infos-consultant-page-date'>
                    Date d&apos;arrivée : le 
                    <input 
                        type="date" 
                        value={formatDate(newConsultant.arrival_date)} 
                        onChange={(event) => handleNewArrivalDateChange(event.target.value)} 
                        className="infos-consultant-input-date" 
                        name="arrival-date" 
                    />
                </p>
            </div>
        </div>
    );
};

ConsultantHeaderInfos.propTypes = {
    newConsultant: PropTypes.shape({
        lastname: PropTypes.string.isRequired,
        firstname: PropTypes.string.isRequired,
        arrival_date: PropTypes.string.isRequired,
    }).isRequired,
    handleNewArrivalDateChange: PropTypes.func.isRequired,
    handleNewLastnameChange: PropTypes.func.isRequired,
    handleNewFirstnameChange: PropTypes.func.isRequired,
};

ConsultantHeaderInfos.defaultProps = {};

export default ConsultantHeaderInfos;