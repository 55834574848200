import { connect } from 'react-redux';
import { checkDocument, uncheckDocument } from '../../actions/documentActions';
import { fetchDocumentsList } from '../../actions/documentActions';

// on importe le composant de présentation
import DocumentsToCheck from '../../components/DocumentsToCheck';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  crealisDocumentsList: state.documentReducer.crealisDocumentsList,
  crealisPortsideDocumentsList: state.documentReducer.crealisPortsideDocumentsList,
  portsideDocumentsList: state.documentReducer.portsideDocumentsList,
  hcsDocumentsList: state.documentReducer.hcsDocumentsList,
  isDocumentsListLoading: state.documentReducer.isDocumentsListLoading,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  loadDocumentsList: (selectedCompany, selectedCompanyValue) => {
    dispatch(fetchDocumentsList(selectedCompany, selectedCompanyValue));
  },
  checkDocument: (selectedDocumentId, consultantId) => {
    dispatch(checkDocument(selectedDocumentId, consultantId));
  },
  uncheckDocument: (selectedDocumentId) => {
    dispatch(uncheckDocument(selectedDocumentId));
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(DocumentsToCheck);