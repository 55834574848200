import axios from "axios";
import { read_cookie } from 'sfcookies';

import {
  FETCH_CONSULTANT,
  FETCH_CONSULTANTS_IN_CHARGE,
  PATCH_CONSULTANT,
  fetchConsultantsInCharge,
  saveConsultant,
  saveConsultantsInCharge,
  setIsConsultantPageLoading,
  setIsConsultantsInChargeListLoading,
  POST_NEW_CONSULTANT,
  setHasNewConsultantBeenCreated,
  ARCHIVE_CONSULTANT,
  setIsConsultantsListLoading,
  FETCH_CONSULTANTS_LIST,
  saveCrealisConsultantsArray,
  savePortsideConsultantsArray,
  saveHCSConsultantsArray,
  fetchConsultantsList,
  DELETE_CONSULTANT,
} from "../actions/consultantActions";
import { setIsMeetingConsultantPageLoading } from "../actions/meetingActions";
import { saveConsultantWeeks, setDefaultDisplayedWeek } from "../actions/weekActions";

// const baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
// const baseUrl = 'http://localhost:8000/'

const consultantMiddleware = (store) => (next) => (action) => {
  
  const currentEnv = store.getState().connexionReducer.appenv;
    let baseUrl = '';
    if (currentEnv === "prod") {
      baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
    } else {
      baseUrl = 'http://localhost:8000/'
    }

  switch (action.type) {
    case FETCH_CONSULTANTS_LIST: {
      const currentCompany = action.companyValue
      axios.get(
        // URL
        `${baseUrl}api/consultants?column=company&value=${currentCompany}`,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
      )
      .then((response) => {
        switch (currentCompany) {
          case 1:
            store.dispatch(saveCrealisConsultantsArray(response.data))
            break;
          case 2:
            store.dispatch(saveCrealisConsultantsArray(response.data))
            break;
          case 3:
            store.dispatch(savePortsideConsultantsArray(response.data))
            break;
          case 4:
            store.dispatch(saveHCSConsultantsArray(response.data))
            break;
          default:
        }
      })
      .then(() => {
        store.dispatch(setIsConsultantsListLoading(false));
      });
      break;
    }
    case FETCH_CONSULTANT:
      axios.get(
        // URL
        `${baseUrl}api/consultants/${action.value}`,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
        )
        .then((response) => {
          store.dispatch(saveConsultant(response.data));
          store.dispatch(saveConsultantWeeks(response.data.report));
          store.dispatch(setDefaultDisplayedWeek());
          store.dispatch(fetchConsultantsInCharge());
        })
        .then(() => {
          store.dispatch(setIsConsultantPageLoading(false));
          store.dispatch(setIsMeetingConsultantPageLoading(false));
        });
      break;
    case POST_NEW_CONSULTANT: {
      const newConsultantToPost = store.getState().consultantReducer.newConsultant;

      axios.post(
        // URL
        `${baseUrl}api/consultants/add`,
        newConsultantToPost,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
        )
        .then(() => {
          store.dispatch(setHasNewConsultantBeenCreated(true));
        });
      break;
    }
    
    case FETCH_CONSULTANTS_IN_CHARGE:
      axios.get(
        // URL
        `${baseUrl}api/consultants?column=does_action&value=1`,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
        )
        .then((response) => {
          store.dispatch(saveConsultantsInCharge(response.data));
        })
        .then(() => {
          store.dispatch(setIsConsultantsInChargeListLoading(false));
        });
      break;
    case PATCH_CONSULTANT: {
      const consultantToPatch = store.getState().consultantReducer.currentConsultant;

      const newConsultantToPatch = {
        id: consultantToPatch.id,
        firstname: consultantToPatch.firstname,
        lastname: consultantToPatch.lastname,
        status: consultantToPatch.status,
        company: +consultantToPatch.company,
        arrival_date: consultantToPatch.arrival_date,
        does_action: consultantToPatch.does_action,
        is_active: consultantToPatch.is_active,
        is_deleted: consultantToPatch.is_deleted
      }

      axios.patch(
        // URL
        `${baseUrl}api/consultants/edit/${newConsultantToPatch.id}`,
        newConsultantToPatch,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
        )
        .then((response) => {
          store.dispatch(saveConsultant(response.data));
        });
      break;
    }
    case ARCHIVE_CONSULTANT: {
      
      const newConsultantToPatch = {
        is_active: action.value,
      }

      axios.patch(
        // URL
        `${baseUrl}api/consultants/edit/${action.id}`,
        newConsultantToPatch,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
        )
        .then((response) => {
          store.dispatch(fetchConsultantsList(+response.data.company));
        });
      break;
    }
    case DELETE_CONSULTANT: 
      axios.delete(
        // URL
        `${baseUrl}api/consultants/delete/${action.id}`,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
        )
        .then(() => {
          store.dispatch(fetchConsultantsList(+action.company));
        });
      break;
    default:
  }

  // on passe l'action au suivant (middleware suivant ou reducer)
  next(action);
};

export default consultantMiddleware;