import { connect } from 'react-redux';
import { setIsConnexionLoading, setLogin, setPassword, submitLogin } from '../../actions/connexionActions';

// on importe le composant de présentation
import ConnexionPage from '../../components/ConnexionPage';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  login: state.connexionReducer.login,
  password: state.connexionReducer.password,
  isAuthentified: state.connexionReducer.isAuthentified,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  setLogin: (newValue) => {
    dispatch(setLogin(newValue));
  },
  setPassword: (newValue) => {
    dispatch(setPassword(newValue));
  },
  setIsConnexionLoading: (newValue) => {
    dispatch(setIsConnexionLoading(newValue));
  },
  submitLogin: () => {
    dispatch(submitLogin());
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(ConnexionPage);