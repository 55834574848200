import { connect } from 'react-redux';
import { archiveConsultant, deleteConsultant, setIsConsultantPageLoading } from '../../../../actions/consultantActions';
import { fetchDocumentsList, setIsDocumentsListLoading } from '../../../../actions/documentActions';

// on importe le composant de présentation
import ConsultantsListTable from '../../../../components/InfosPage/ConsultantsList/ConsultantsListTable';

// === mapStateToProps
// si on a besoin de lire des informations dans le state
const mapStateToProps = (state) => ({
  // nom de la prop à remplir: élément à récupérer dans le state
  crealisDocumentsList: state.documentReducer.crealisDocumentsList,
  crealisPortsideDocumentsList: state.documentReducer.crealisPortsideDocumentsList,
  portsideDocumentsList: state.documentReducer.portsideDocumentsList,
  hcsDocumentsList: state.documentReducer.hcsDocumentsList,
  isConsultantsListLoading: state.consultantReducer.isConsultantsListLoading,
});

// === mapDispatchToProps
// si on a besoin de dispatcher des actions vers le store (modifier le state)
const mapDispatchToProps = (dispatch) => ({
  // nom de la prop à remplir: fonction qui dispatch l'action
  setIsConsultantPageLoading: (newValue) => {
    dispatch(setIsConsultantPageLoading(newValue));
  },
  setIsDocumentsListLoading: (newValue) => {
    dispatch(setIsDocumentsListLoading(newValue));
  },
  archiveConsultant: (consultantId, newValue) => {
    dispatch(archiveConsultant(consultantId, newValue));
  },
  deleteConsultant: (consultantId, consultantCompany) => {
    dispatch(deleteConsultant(consultantId, consultantCompany));
  },
  loadDocumentsList: (selectedCompany, selectedCompanyValue) => {
    dispatch(fetchDocumentsList(selectedCompany, selectedCompanyValue));
  },
});

// === création de l'assistant
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantsListTable);