import {
    SET_WEEK_NUMBER,
    SET_START_DATE,
    SET_END_DATE,
    SAVE_CURRENT_MEETING_WEEK,
    SET_IS_MEETING_CONSULTANTS_LIST_LOADING,
    SAVE_ACTIVE_CONSULTANTS_ARRAY,
    SAVE_CURRENT_MEETING_CONSULTANT,
    SET_IS_MEETING_CONSULTANT_PAGE_LOADING,
    DISPLAY_MEETING_CONSULTANTS_LIST,
    SET_ARE_NEW_REPORTS_CREATED,
    FILTER_MEETING_CONSULTANTS_LIST,
    VALIDATE_REPORT_STATUS,
    SET_HAS_MEETING_STARTED,
    SET_IS_COMPANY_CHECKED,
    SET_FILTER_VALUES_ARRAY,
} from '../actions/meetingActions';

const initialState = {
    // ici le state initial
    inputWeek: {
        weekNumber: 1,
        startDate: '',
        endDate: '',
    },
    currentMeetingWeek: {},
    isMeetingConsultantsListLoading: true,
    isMeetingConsultantPageLoading: true,
    isMeetingConsultantsListDisplayed: false,
    areNewReportsCreated: false,
    meetingConsultantsArray: [],
    activeConsultantsArray: [],
    currentMeetingConsultant: {},
    validateReportsArray: [],
    lastValidateReportConsultantId: 1,
    hasMeetingStarted: false,
    includeCrealis: true,
    includePortside: true,
    includeCrealisPortside: true,
    includeHCS: true,
    filterValuesArray: [1, 2, 3, 4],
};

function meetingReducer(state = initialState, action) {
    switch (action.type) {
        case DISPLAY_MEETING_CONSULTANTS_LIST:
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                isMeetingConsultantsListDisplayed: true,
            };
        case SET_ARE_NEW_REPORTS_CREATED:
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                areNewReportsCreated: action.value,
            };
        case SET_WEEK_NUMBER:
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                inputWeek: {
                    ...state.inputWeek,
                    weekNumber: action.value,
                },
            };
        case SET_START_DATE:
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                inputWeek: {
                    ...state.inputWeek,
                    startDate: action.value,
                },
            };
        case SET_END_DATE:
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                inputWeek: {
                    ...state.inputWeek,
                    endDate: action.value,
                },
            };
        case SET_IS_MEETING_CONSULTANT_PAGE_LOADING:
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                isMeetingConsultantPageLoading: action.value,
            };
        case SET_IS_MEETING_CONSULTANTS_LIST_LOADING:
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                isMeetingConsultantsListLoading: action.value,
            };
        case SAVE_CURRENT_MEETING_WEEK:
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                currentMeetingWeek: action.value,
            };
        case SAVE_ACTIVE_CONSULTANTS_ARRAY: {
            // add a boolean to check if the consultant's report has been modified or not
            let currentConsultantMeetingId = 0;
            let currentLastValidateReportConsultantId = 0;
            let newMeetingConsultantsArray = [...action.value];
            newMeetingConsultantsArray.forEach((consultant) => {
                consultant.has_report_been_modified = false;
                currentConsultantMeetingId += 1;
                consultant.consultant_meeting_id = currentConsultantMeetingId;
                if (state.validateReportsArray.indexOf(consultant.id) !== -1) {
                    consultant.has_report_been_modified = true; 
                    currentLastValidateReportConsultantId = currentConsultantMeetingId;
                }
            })
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                activeConsultantsArray: [...action.value],
                meetingConsultantsArray: newMeetingConsultantsArray,
                isMeetingConsultantsListLoading: false,
                lastValidateReportConsultantId: currentLastValidateReportConsultantId,
            };
        }
        case FILTER_MEETING_CONSULTANTS_LIST: {
            // add a boolean to check if the consultant's report has been modified or not
            const selectedCompanyValuesArray = [...action.values];
            let newMeetingConsultantsArray = [];
            let currentConsultantMeetingId = 0;
            state.activeConsultantsArray.forEach((consultant) => {
                if (selectedCompanyValuesArray.indexOf(+consultant.company) !== -1) {
                    currentConsultantMeetingId += 1;
                    consultant.consultant_meeting_id = currentConsultantMeetingId;
                    newMeetingConsultantsArray.push({...consultant})
                }
            })
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                meetingConsultantsArray: newMeetingConsultantsArray,
                isMeetingConsultantsListLoading: false,
            };
        }
        case SAVE_CURRENT_MEETING_CONSULTANT:
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                currentMeetingConsultant: action.value,
            };
        case VALIDATE_REPORT_STATUS: {
            const meetingConsultantId = action.id;
            let newValidateReportsArray = [...state.validateReportsArray];
            if (newValidateReportsArray.indexOf(meetingConsultantId) === -1) {
                newValidateReportsArray.push(meetingConsultantId)
            }
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                validateReportsArray: newValidateReportsArray,
            };
        }
        case SET_HAS_MEETING_STARTED:
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                hasMeetingStarted: action.value,
            };
        case SET_IS_COMPANY_CHECKED:
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                [action.company]: action.value,
            };
        case SET_FILTER_VALUES_ARRAY: {
            const inputValue = action.value;
            let newFilterValuesArray = [];
            if (state.filterValuesArray.indexOf(inputValue) !== -1) {
                newFilterValuesArray = state.filterValuesArray.filter(value => value !== inputValue);
            } else {
                newFilterValuesArray = [...state.filterValuesArray, inputValue];
            }
            // on retourne un nouveau state
            return {
                // en déversant les informations du state actuel
                ...state,
                // et en appliquant des modifications
                filterValuesArray: newFilterValuesArray,
            };
        }   
        default:
            return state;
    }
}

export default meetingReducer;