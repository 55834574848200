import PropTypes from 'prop-types';
import { useState } from 'react';

import Report from '../../containers/Report';
import { formatFrenchDate } from '../../scripts/functions';
import Loader from '../Loader';

import './styles.scss';

const ReportsList = ({
    search,
    searchedWeeks,
    currentConsultantReportsWeeks,
    filterSearchedWeeks,
    currentWeek,
    handleSearch,
    areCurrentConsultantReportsLoading,
    setCurrentWeek,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    if (currentConsultantReportsWeeks.length === 0) {
        setCurrentWeek(0);
    }

    return (
        <div className="reports-list-container">
            {areCurrentConsultantReportsLoading
            && (
                <Loader />
            )}
            {!areCurrentConsultantReportsLoading
            && (
                <>
                {/* create searchbar to choose more easily the week we want to display */}
                    <div className="reports-list-searchbar">
                        <h3 className="reports-list-searchbar-subtitle">Sélectionner la semaine :</h3>
                        <div className="reports-list-searchbar-container">
                            <label className="reports-list-searchbar-label" htmlFor='week'>
                                Semaine n°
                            </label>
                            <div className="reports-list-searchbar-container-div">
                                <input
                                    placeholder="..."
                                    className="reports-list-searchbar-input"
                                    type="text"
                                    value={search}
                                    name="week"
                                    onChange={(event) => {
                                        handleSearch(event.target.value);
                                        filterSearchedWeeks(event.target.value);
                                        setIsOpen(true);
                                    }}
                                />
                                {isOpen
                                && (
                                    <div className="reports-list-searchbar-results">
                                        <ul className="reports-list-searchbar-results-list">
                                            {searchedWeeks.length === 0
                                            && (
                                                <li className="reports-list-searchbar-results-list-item">
                                                    Aucun compte-rendu trouvé.
                                                </li>
                                            )}
                                            {searchedWeeks.length !== 0
                                            && searchedWeeks.map((week) => (
                                                    <li
                                                        className="reports-list-searchbar-results-list-item"
                                                        key={week.id}
                                                        onClick={() => {
                                                            setCurrentWeek(week.id);
                                                            handleSearch(week.number);
                                                            setIsOpen(false);
                                                        }}
                                                    >
                                                        {week.number} (du {formatFrenchDate(week.start_date)} au {formatFrenchDate(week.end_date)})
                                                    </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {currentConsultantReportsWeeks.length === 0
                    && (
                        <div className="reports-list-reports">
                            Aucun compte-rendu n&apos;a été saisi pour ce consultant.
                        </div>
                    )}
                    {currentWeek !== 0
                    && (
                        <div className="reports-list-reports">
                            <Report currentWeek={currentWeek} isInfosConsultantPage isMeetingConsultantPage={false} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

ReportsList.propTypes = {
    search: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    searchedWeeks: PropTypes.array.isRequired,
    currentConsultantReportsWeeks: PropTypes.array.isRequired,
    currentWeek: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    handleSearch: PropTypes.func.isRequired,
    filterSearchedWeeks: PropTypes.func.isRequired,
    areCurrentConsultantReportsLoading: PropTypes.bool.isRequired,
    setCurrentWeek: PropTypes.func.isRequired,
};


// ReportsList.defaultProps = {
// };

export default ReportsList;