// import PropTypes from 'prop-types';
import { TailSpin } from "react-loader-spinner"

// import './styles.scss';

const Loader = () => (
    <TailSpin color="#900" height={50} width={50} wrapperStyle={{ display: 'flex', justifyContent: 'center' }} />
);

// Loader.propTypes = {
// };


// Loader.defaultProps = {
// };

export default Loader;