import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import consultantMiddleware from '../middlewares/consultantMiddleware';
import documentMiddleware from '../middlewares/documentMiddleware';
import meetingMiddleware from '../middlewares/meetingMiddleware';
import reportMiddleware from '../middlewares/reportMiddleware';
import actionMiddleware from '../middlewares/actionMiddleware';
import connexionMiddleware from '../middlewares/connexionMiddleware';
import reducer from '../reducers';

// on combine devTools avec les middlewares
const enhancers = composeWithDevTools(
  applyMiddleware(
    documentMiddleware,
    consultantMiddleware,
    meetingMiddleware,
    reportMiddleware,
    connexionMiddleware,
    actionMiddleware,
    // ... d'autres middlewares
  ),
);

const store = createStore(
  // reducer
  reducer,
  // enhancers
  enhancers,
);

export default store;