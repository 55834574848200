// import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './styles.scss';
import MeetingList from '../../containers/MeetingPage/MeetingList';

const MeetingPage = ({
    weekNumber,
    startDate,
    endDate,
    handleWeekNumberChange,
    handleStartDateChange,
    handleEndDateChange,
    postWeek,
    meetingConsultantsArray,
    createNewReport,
    isMeetingConsultantsListLoading,
    isMeetingConsultantsListDisplayed,
    displayMeetingConsultantsList,
    areNewReportsCreated,
    setAreNewReportsCreated,
    lastValidateReportConsultantId,
    hasMeetingStarted,
    setHasMeetingStarted,
    loadMeetingConsultant,
    setIsMeetingConsultantPageLoading
}) => {
    // const [hasMeetingStarted, setHasMeetingStarted] = useState(false);

    return (
        <div className="meeting-page-container">
        <h1 className="meeting-page-title">Démarrer une nouvelle réunion</h1>
            <div className='meeting-page-body'>
                <div className='meeting-page-form-week-container'>
                    <h3 className='meeting-page-form-week-subtitle'>
                        Déterminer la semaine en cours :
                    </h3>
                    <div className='meeting-page-form-week-form'>
                        <label
                            htmlFor="week-number"
                            className="meeting-page-form-week-label"
                        >
                            Semaine
                        </label>
                        <input
                            type="number"
                            min="1"
                            value={weekNumber}
                            onChange={(event) => handleWeekNumberChange(event.target.value)}
                            className="meeting-page-form-week-input meeting-page-form-week-input-week-number"
                            name="week-number"
                        />
                        <label
                            htmlFor="start-date"
                            className="meeting-page-form-week-label"
                        >
                            du
                        </label> 
                        <input
                            type="date"
                            value={startDate}
                            onChange={(event) => handleStartDateChange(event.target.value)}
                            className="meeting-page-form-week-input meeting-page-form-week-input-start-date"
                            name="start-date"
                        />
                        <label
                            htmlFor="end-date"
                            className="meeting-page-form-week-label"
                        >
                            au
                        </label> 
                        <input
                            type="date"
                            value={endDate}
                            onChange={(event) => handleEndDateChange(event.target.value)}
                            className="meeting-page-form-week-input meeting-page-form-week-input-end-date"
                            name="end-date"
                        />
                        <button
                            className="meeting-page-form-week-button"
                            onClick={() => {
                                postWeek();
                                displayMeetingConsultantsList();
                            }}
                        >
                            Confirmer
                        </button>
                    </div>
                </div>
                {isMeetingConsultantsListDisplayed
                &&
                (<>
                    
                    
                    <MeetingList />
                
                    {!isMeetingConsultantsListLoading
                    && (
                        <div className="meeting-page-buttons-container">
                            {!hasMeetingStarted
                            && (
                                <>
                                    <button 
                                        className="meeting-page-button"
                                        onClick={() => {
                                            meetingConsultantsArray.forEach((consultant) => {
                                                createNewReport(consultant.id)
                                            });
                                            loadMeetingConsultant(1);
                                            setAreNewReportsCreated(true);
                                        }}
                                    >
                                        Créer les comptes-rendus
                                    </button>

                                    {areNewReportsCreated
                                    && (
                                    <Link
                                        to="/reunion/1"
                                    >
                                        <button 
                                            className="meeting-page-button"
                                            onClick={() => {
                                                setIsMeetingConsultantPageLoading(true);
                                                setHasMeetingStarted(true);
                                            }}
                                        >
                                            Démarrer la réunion
                                        </button>
                                    </Link>
                                    )}
                                </>
                            )
                            }
                            {hasMeetingStarted
                            && (
                                <>
                                    <Link 
                                        to={`/reunion/${lastValidateReportConsultantId + 1}`}
                                    >
                                        <button className="meeting-page-button">
                                            Reprendre la réunion
                                        </button>
                                    </Link>
                                    {/* <Link to="/comptes-rendus/1">
                                        <button className="meeting-page-button">
                                            Afficher le compte-rendu
                                        </button>
                                    </Link> */}
                                </>
                            )}
                        </div>
                    )}
                </>
                )}

            </div>
        </div>
    );
};

MeetingPage.propTypes = {
    weekNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
    startDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
      ]).isRequired,
      endDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]).isRequired,
    handleWeekNumberChange: PropTypes.func.isRequired,
    handleStartDateChange: PropTypes.func.isRequired,
    handleEndDateChange: PropTypes.func.isRequired,
    meetingConsultantsArray: PropTypes.array,
    postWeek: PropTypes.func.isRequired,
    createNewReport: PropTypes.func.isRequired,
    isMeetingConsultantsListLoading: PropTypes.bool.isRequired,
    isMeetingConsultantsListDisplayed: PropTypes.bool.isRequired,
    displayMeetingConsultantsList: PropTypes.func.isRequired,
    areNewReportsCreated: PropTypes.bool.isRequired,
    setAreNewReportsCreated: PropTypes.func.isRequired,
    lastValidateReportConsultantId: PropTypes.number.isRequired,
    hasMeetingStarted: PropTypes.bool.isRequired,
    setHasMeetingStarted: PropTypes.func.isRequired,
    loadMeetingConsultant: PropTypes.func.isRequired,
    setIsMeetingConsultantPageLoading: PropTypes.func.isRequired,
};

MeetingPage.defaultProps = {
    meetingConsultantsArray: [],
};

export default MeetingPage;