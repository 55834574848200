import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import './styles.scss';

const ConsultantFormInfos = ({ currentConsultant, handleDoesActionChange, handleStatusChange, handleCompanyChange }) => {
    return (
    <div className="consultant-form-infos-container">
        <div className="consultant-form-infos-row">
            <div className="consultant-form-infos-item">
                <p>peut être en charge d&apos;une action :</p>
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <Select
                    value={currentConsultant.does_action}
                    displayEmpty
                    onChange={(event) => handleDoesActionChange(event.target.value)}
                    >
                        <MenuItem value={true}>
                            <p className="consultant-form-infos-item-option">
                                Oui
                            </p>
                        </MenuItem>
                        <MenuItem value={false}>
                            <p className="consultant-form-infos-item-option">
                                Non
                            </p>
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
        <div className="consultant-form-infos-row">
            <div className="consultant-form-infos-item">
                <p>Statut :</p>
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <Select
                        value={currentConsultant.status}
                        displayEmpty
                        onChange={(event) => handleStatusChange(event.target.value)}
                    >
                        <MenuItem value={"Salarié cadre"}>
                            <p className="consultant-form-infos-item-option">
                                Salarié cadre
                            </p>
                        </MenuItem>
                        <MenuItem value={"Salarié non cadre"}>
                            <p className="consultant-form-infos-item-option">
                                Salarié non cadre
                            </p>
                        </MenuItem>
                        <MenuItem value={"Indépendant"}>
                            <p className="consultant-form-infos-item-option">
                                Indépendant
                            </p>
                        </MenuItem>
                        <MenuItem value={"Portage"}>
                            <p className="consultant-form-infos-item-option">
                                Portage
                            </p>
                        </MenuItem>
                        <MenuItem value={"Dirigeant"}>
                            <p className="consultant-form-infos-item-option">
                                Dirigeant
                            </p>
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="consultant-form-infos-item">
                <p>Entreprise :</p>
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <Select
                        value={currentConsultant.company}
                        displayEmpty
                        onChange={(event) => handleCompanyChange(event.target.value)}
                    >
                        <MenuItem value={1}>
                            <p className="consultant-form-infos-item-option">
                                Créalis Ingénierie
                            </p>
                        </MenuItem>
                        <MenuItem value={2}>
                            <p className="consultant-form-infos-item-option">
                                Créalis Ingénierie/Portside
                            </p>
                        </MenuItem>
                        <MenuItem value={3}>
                            <p className="consultant-form-infos-item-option">
                                Portside
                            </p>
                        </MenuItem>
                        <MenuItem value={4}>
                            <p className="consultant-form-infos-item-option">
                                HCS Groupe
                            </p>
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    </div>
  );
};

ConsultantFormInfos.propTypes = {
    currentConsultant: PropTypes.shape({
        status: PropTypes.string,
        company: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
        does_action: PropTypes.bool,
    }),
    handleDoesActionChange: PropTypes.func.isRequired,
    handleStatusChange: PropTypes.func.isRequired,
    handleCompanyChange: PropTypes.func.isRequired,
};

ConsultantFormInfos.defaultProps = {
    currentConsultant: {
        status: '',
        company: '',
        does_action: false,
    },
};
export default ConsultantFormInfos;