import axios from "axios";
import { read_cookie } from 'sfcookies';
import { fetchConsultant } from "../actions/consultantActions";

import {
  addNewAction,
  addNewClient,
  deleteAction,
  deleteClient,
  setNewValueAction,
  DELETE_DB_ACTION,
  DELETE_DB_CLIENT,
  PATCH_ACTION,
  PATCH_CLIENT,
  PATCH_COMMENT,
  POST_NEW_ACTION,
  POST_NEW_CLIENT,
  POST_NEW_REPORT,
  addNewReportNewClient,
  deleteNewReportClient,
  addNewReportNewAction,
  deleteNewReportAction,
  setNewReportNewValueAction,
  POST_ACTION_TO_NEW_REPORT,
  POST_CLIENT_TO_NEW_REPORT,
  DELETE_REPORT,
  // setComment,
} from "../actions/reportActions";

import { formatDate } from "../scripts/functions";

// const baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
// const baseUrl = 'http://localhost:8000/'

const reportMiddleware = (store) => (next) => (action) => {

  const currentEnv = store.getState().connexionReducer.appenv;
  let baseUrl = '';
  if (currentEnv === "prod") {
    baseUrl = 'https://backoffice-reunion.crealis-ingenierie.com/'
  } else {
    baseUrl = 'http://localhost:8000/'
  }

  switch (action.type) {
    case POST_NEW_REPORT: {
      const meetingWeekId = store.getState().meetingReducer.currentMeetingWeek.id;
      const consultantId = action.id;

      const newReportToPost = {
        comment: "",
        week: meetingWeekId,
        consultant: consultantId,
      };

      axios.post(
        // URL
        `${baseUrl}api/reports/add`,
        newReportToPost,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
      )
        .then(() => {
          // store.dispatch(addNewClient(response.data));
        })
        .catch(() => {
          // store.dispatch(errorLogin('Identifiants incorrects'));
        });
      break;
    }
    case PATCH_COMMENT: {
      const newReportToPatch = {
        comment: action.value,
      }

      axios.patch(
        // URL
        `${baseUrl}api/reports/edit/${action.id}`,
        newReportToPatch,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
        )
      break;
    }
    case POST_NEW_CLIENT: {
      const reportId = action.id;
      const todayDate = formatDate(Date().toLocaleString());

      const newClientToPost = {
        name: "",
        date: todayDate,
        report: reportId,
      };

      axios.post(
        // URL
        `${baseUrl}api/clients/add`,
        newClientToPost,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
      )
        .then((response) => {
          if (action.isNewReport) {
            store.dispatch(addNewReportNewClient(response.data));
          } else {
            store.dispatch(addNewClient(response.data));
          }
        })
        .catch(() => {
          // store.dispatch(errorLogin('Identifiants incorrects'));
        });
      break;
    }
    case POST_CLIENT_TO_NEW_REPORT: {
      const reportId = action.id;

      const newClientToPost = {
        ...action.value,
        report: reportId,
      };

      axios.post(
        // URL
        `${baseUrl}api/clients/add`,
        newClientToPost,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
      )
        .then((response) => {
          store.dispatch(addNewReportNewClient(response.data));
        })
        .catch(() => {
          // store.dispatch(errorLogin('Identifiants incorrects'));
        });
      break;
    }
    case PATCH_CLIENT: {
      const clientId = action.id;

      const clientToPatch = {
        name: action.name,
        date: action.date,
      };

      axios.patch(
        // URL
        `${baseUrl}api/clients/edit/${clientId}`,
        clientToPatch,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
      )
      break;
    }
    case DELETE_DB_CLIENT: {
      axios.delete(
        // URL
        `${baseUrl}api/clients/delete/${action.id}`,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
      )
      .then(() => {
        if (action.isNewReport) {
          store.dispatch(deleteNewReportClient(action.id));
        } else {
          store.dispatch(deleteClient(action.id));
        }
      })
      break;
    }
    case POST_NEW_ACTION: {
      const reportId = action.id;
      const todayDate = formatDate(Date().toLocaleString());

      const newActionToPost = {
        description: '',
        person_in_charge: '',
        creation_date: todayDate,
        is_done: false,
        done_date: null,
        is_important: false,
        report: reportId,
      };

      axios.post(
        // URL
        `${baseUrl}api/actions/add`,
        newActionToPost,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
      )
        .then((response) => {
          if (action.isNewReport) {
            store.dispatch(addNewReportNewAction(response.data));
          } else {
            store.dispatch(addNewAction(response.data));
          }
        })
        .catch(() => {
          // store.dispatch(errorLogin('Identifiants incorrects'));
        });
      break;
    }
    case POST_ACTION_TO_NEW_REPORT: {
      const reportId = action.id;

      const newActionToPost = {
        ...action.value,
        report: reportId
      };

      axios.post(
        // URL
        `${baseUrl}api/actions/add`,
        newActionToPost,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
      )
        .then((response) => {
          store.dispatch(addNewReportNewAction(response.data));
        })
        .catch(() => {
          // store.dispatch(errorLogin('Identifiants incorrects'));
        });
      break;
    }
    case PATCH_ACTION: {
      const actionId = action.id;

      const actionToPatch = {
        [action.property]: action.value,
      };

      axios.patch(
        // URL
        `${baseUrl}api/actions/edit/${actionId}`,
        actionToPatch,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
      )
        .then((response) => {
          if (action.isNewReport !== null) {
            if (action.isNewReport) {
              store.dispatch(setNewReportNewValueAction(response.data.id, response.data));
            } else {
              store.dispatch(setNewValueAction(response.data.id, response.data));
            }
          }
        })
        .catch(() => {
          // store.dispatch(errorLogin('Identifiants incorrects'));
        });
      break;
    }
    case DELETE_DB_ACTION: {
      axios.delete(
        // URL
        `${baseUrl}api/actions/delete/${action.id}`,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
      )
      .then(() => {
        if (action.isNewReport) {
          store.dispatch(deleteNewReportAction(action.id));
        } else {
          store.dispatch(deleteAction(action.id));
        }
      })
      break;
    }
    case DELETE_REPORT: {
      const currentConsultantId = store.getState().consultantReducer.currentConsultant.id
      axios.delete(
        // URL
        `${baseUrl}api/reports/delete/${action.id}`,
        {
          headers: {
            Authorization: `Bearer ${read_cookie('jwt')}`,
          },
        },
      )
        .then(() => {
          store.dispatch(fetchConsultant(currentConsultantId));
        })
        .catch(() => {
          // store.dispatch(errorLogin('Identifiants incorrects'));
        });
      break;
    }
    default:
  }

  // on passe l'action au suivant (middleware suivant ou reducer)
  next(action);
};

export default reportMiddleware;