import {
  SAVE_CREALIS_DOCUMENTS_ARRAY,
  SAVE_CREALIS_PORTSIDE_DOCUMENTS_ARRAY,
  SAVE_HCS_DOCUMENTS_ARRAY,
  SAVE_PORTSIDE_DOCUMENTS_ARRAY,
  SET_IS_DOCUMENTS_LIST_LOADING,
} from '../actions/documentActions';

const initialState = {
  // ici le state initial
  crealisDocumentsList: [],
  crealisPortsideDocumentsList: [],
  portsideDocumentsList: [],
  hcsDocumentsList: [],
  isDocumentsListLoading: true,
};

function documentReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_CREALIS_DOCUMENTS_ARRAY:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        crealisDocumentsList: action.value,
        isDocumentsListLoading: false,
      };
    case SAVE_CREALIS_PORTSIDE_DOCUMENTS_ARRAY:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        crealisPortsideDocumentsList: action.value,
        isDocumentsListLoading: false,
      };
    case SAVE_PORTSIDE_DOCUMENTS_ARRAY:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        portsideDocumentsList: action.value,
        isDocumentsListLoading: false,
      };
    case SAVE_HCS_DOCUMENTS_ARRAY:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        hcsDocumentsList: action.value,
        isDocumentsListLoading: false,
      };
    case SET_IS_DOCUMENTS_LIST_LOADING:
      // on retourne un nouveau state
      return {
        // en déversant les informations du state actuel
        ...state,
        // et en appliquant des modifications
        isDocumentsListLoading: action.value,
      };
    default:
      return state;
  }
}

export default documentReducer;