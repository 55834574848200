import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ConsultantHeaderInfos from '../../containers/InfosConsultantForm/ConsultantHeaderInfos';
import ConsultantFormInfos from '../../containers/InfosConsultantForm/ConsultantFormInfos';

import './styles.scss';
import { useEffect } from 'react';

const InfosConsultantForm = ({ postNewConsultant, hasNewConsultantBeenCreated, resetNewConsultant }) => {
    useEffect(() => {
        return () => {
            resetNewConsultant();
        }
    }, []);

    return (
        <div className="infos-consultant-page-container">
            <ConsultantHeaderInfos />
            <ConsultantFormInfos />
            <div className="infos-consultant-page-buttons-container">
                <Link to="/consultants">
                    <button className="infos-consultant-page-button">
                        Retour à la liste
                    </button>
                </Link>
                <Link to={`/consultants/add`}>
                    <button
                        className="infos-consultant-page-button"
                        onClick={() => {
                            postNewConsultant();
                        }}
                    >
                        Valider
                    </button>
                </Link>
            </div>
            <div className="infos-consultant-page-buttons-container">
            {hasNewConsultantBeenCreated
            && (
                <p>Le consultant a bien été créé</p>
            )}
            </div>

        </div>
    )
};

InfosConsultantForm.propTypes = {
    postNewConsultant: PropTypes.func.isRequired,
    hasNewConsultantBeenCreated: PropTypes.bool.isRequired,
    resetNewConsultant: PropTypes.func.isRequired,
};

export default InfosConsultantForm;