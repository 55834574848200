import './styles.scss';

const LegalMentions = () => (
    <div className="legal-mentions-page-container">
        <div className="legal-mentions-page-div">
            <p className="legal-mentions-page-paragraph">
                Ce site Internet est la propriété de HCS, SAS au capital de 202 000€ dont le siège social se situe au 32 avenue Léonard de Vinci – 33600 PESSAC.
            </p>
            <p className="legal-mentions-page-paragraph">
                Tél: 05 57 53 02 09
            </p>
            <p className="legal-mentions-page-paragraph">
                E-mail: hcs@hcs-groupe.com
            </p>
            <p className="legal-mentions-page-paragraph">
                SIRET : 798 715 066 00022 RCS Bordeaux
            </p>
            <p className="legal-mentions-page-paragraph">
                TVA intracom. : FR 91 798 715 066
            </p>
            <p className="legal-mentions-page-paragraph">
                Le Directeur de la Publication est Monsieur Julien Bach, son Gérant.
            </p>
            <p className="legal-mentions-page-paragraph">
                Ce site est hébergé par la société OVH située au 2 rue Kellermann – 59100 ROUBAIX,
                Tél: 09 72 10 10 07
            </p>
        </div>

        <div className="legal-mentions-page-div">
            <h3 className="legal-mentions-page-subtitle">
                INFORMATIONS PERSONNELLES
            </h3>
            <p className="legal-mentions-page-paragraph">
                Les informations recueillies font l’objet d’un traitement informatique destiné à la communication volontaire par les internautes de leur CV dans le cadre d’une candidature spontanée ou d’une réponse à la publication d’une offre d’emploi. 
            </p>

            <p className="legal-mentions-page-paragraph">
                Nous vous informons également que les informations recueillies par le biais du formulaire de contact ne sauraient être divulguées à des personnes autres que celles composant notre société.
            </p>
            <p className="legal-mentions-page-paragraph">
                Créalis Ingénierie s’engage à réserver ces données à ce seul usage et garantit la confidentialité de celles-ci.
                Ces données ne peuvent en aucun cas être cédées ou rendues accessibles à une entité tierce.
            </p>
            <p className="legal-mentions-page-paragraph">
                Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, que vous pouvez exercer en vous adressant à <a href="mailto:crealisinfo@crealis-ingenierie.com">crealisinfo@crealis-ingenierie.com</a>.
            </p>
            <p className="legal-mentions-page-paragraph">
                Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant.
            </p>
        </div>

        <div className="legal-mentions-page-div">
            <h3 className="legal-mentions-page-subtitle">
                PROPRIÉTÉ INTELLECTUELLE ET DROITS RÉSERVÉS
            </h3>
            <p className="legal-mentions-page-paragraph">
                Tous les droits sur le matériel et le contenu de ce site (comprenant, de manière non limitative, les textes, les images, les pages web, les sons et les logiciels avec les codes, les interfaces et la structure du site web) ainsi que la vidéo, la présentation visuelle et l’esprit, le design et leur compilation, sont la propriété de la société Créalis Ingénierie et celle de ceux qui nous ont octroyé un droit d’utilisation.
            </p>
            <p className="legal-mentions-page-paragraph">
                L’internaute accepte que ce matériel soit utilisé conformément à ces conditions ou suivant les termes d’une autorisation écrite de notre part, des sociétés de notre groupe ou de ceux qui nous ont octroyé des droits d’utilisation, et qu’il est interdit de copier, de multiplier, de diffuser, de rendre public, de distribuer, d’exploiter commercialement, d’adapter, de traduire, de modifier, de fusionner, de partager ou de mettre à disposition d’une personne quelconque ce matériel de quelque manière que ce soit à des fins commerciales ou d’en faire des produits dérivés.
            </p>
            <p className="legal-mentions-page-paragraph">
            </p>
        </div>

        <div className="legal-mentions-page-div">
            <h3 className="legal-mentions-page-subtitle">
                LES LIENS HYPERTEXTES
            </h3>
            <p className="legal-mentions-page-paragraph">
                L’internaute accepte que nous n’ayons aucune responsabilité dans l’exactitude et la disponibilité des informations proposées par les sites web auxquels il peut accéder via les liens de ce site web (« sites liés »).
            </p>
            <p className="legal-mentions-page-paragraph">
                Le fait que des liens soient offerts vers de tels sites ne signifie aucunement que la société Créalis Ingénierie soit d’accord ou concerné de quelque manière que ce soit par de tels sites ou par leur contenu, leurs produits, leurs publicités ou autres matériels.
            </p>
            <p className="legal-mentions-page-paragraph">
            </p>
        </div>
    </div>
);

export default LegalMentions;