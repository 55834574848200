import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faPenSquare } from '@fortawesome/free-solid-svg-icons';
// import 

import './styles.scss';

const TopHeader = () => (
    <div className="top-header-container">
        <a href="https://backoffice-reunion.crealis-ingenierie.com/public/" rel="noreferrer noopener" target="_blank" className='top-header-link'>
            <FontAwesomeIcon icon={faPenSquare} />
            <p>Accéder au BackOffice</p>
        </a>
        <a href="https://extranet.crealis-ingenierie.com/prod" rel="noreferrer noopener" target="_blank" className='top-header-link'>
            <FontAwesomeIcon icon={faDesktop} />
            <p>Retour vers l&apos;extranet</p>
        </a>
    </div>
);

export default TopHeader;