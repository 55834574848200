// import axios from 'axios';
import {
    useEffect,
    // useState
} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import MeetingListTable from '../../../containers/MeetingPage/MeetingList/MeetingListTable';

import './styles.scss';

const MeetingList = ({
    setIsMeetingConsultantsListLoading,
    loadActiveConsultants,
    meetingConsultantsArray,
    filterMeetingConsultantsList,
    includeCrealis,
    includePortside,
    includeCrealisPortside,
    includeHCS,
    setIsCompanyChecked,
    filterValuesArray,
    setFilterValuesArray,
}) => {

    useEffect(() => {
        loadActiveConsultants(filterValuesArray);
        return () => {
            setIsMeetingConsultantsListLoading(true);
        }
    },
        []);

    return (
        <>
            <div className='meeting-page-form-list-container'>
                <h3 className='meeting-page-form-list-subtitle'>
                    Sélectionner la liste des consultants :
                </h3>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={includeCrealis}
                                    onChange={(event) => {
                                        setIsCompanyChecked(event.target.name, event.target.checked);
                                        setFilterValuesArray(+event.target.value);
                                    }}
                                    name="includeCrealis"
                                    value={1}
                                />
                            }
                            label="Créalis Ingénierie"
                            sx={{ mx: 2 }}
                            disableTypography
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={includeCrealisPortside}
                                    onChange={(event) => {
                                        setIsCompanyChecked(event.target.name, event.target.checked);
                                        setFilterValuesArray(+event.target.value);
                                    }}
                                    name="includeCrealisPortside"
                                    value={2}
                                />
                            }
                            label="Créalis Ingénierie/Portside"
                            sx={{ mx: 2 }}
                            disableTypography
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={includePortside}
                                    onChange={(event) => {
                                        setIsCompanyChecked(event.target.name, event.target.checked);
                                        setFilterValuesArray(+event.target.value);
                                    }}
                                    name="includePortside"
                                    value={3}
                                />
                            }
                            label="Portside"
                            sx={{ mx: 2 }}
                            disableTypography
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={includeHCS}
                                    onChange={(event) => {
                                        setIsCompanyChecked(event.target.name, event.target.checked);
                                        setFilterValuesArray(+event.target.value);
                                    }}
                                    name="includeHCS"
                                    value={4}
                                />
                            }
                            label="HCS Groupe"
                            sx={{ mx: 2 }}
                            disableTypography
                        />
                    </FormGroup>
                </FormControl>
                <button
                    className="meeting-page-form-list-button"
                    onClick={() => {
                        filterMeetingConsultantsList(filterValuesArray);
                    }}
                >
                    Filtrer la liste
                </button>
            </div>
            <div className="meeting-list-container">
                <div className="meeting-list-header">
                    <h3 className='meeting-list-subtitle'>
                        Nombre de consultants : {meetingConsultantsArray.length}
                    </h3>
                    <button className='meeting-list-add-button'>
                        <Link to="/consultants/add">
                            Créer un nouveau consultant
                        </Link>
                    </button>
                </div>
                <div className='meeting-list-table-container'>
                    <MeetingListTable consultantsList={meetingConsultantsArray} />
                </div>
            </div>
        </>
    )
};

MeetingList.propTypes = {
    setIsMeetingConsultantsListLoading: PropTypes.func.isRequired,
    loadActiveConsultants: PropTypes.func.isRequired,
    meetingConsultantsArray: PropTypes.array.isRequired,
    filterMeetingConsultantsList: PropTypes.func.isRequired,
    includeCrealis: PropTypes.bool.isRequired,
    includePortside: PropTypes.bool.isRequired,
    includeCrealisPortside: PropTypes.bool.isRequired,
    includeHCS: PropTypes.bool.isRequired,
    filterValuesArray: PropTypes.array.isRequired,
    setFilterValuesArray: PropTypes.func.isRequired,
    setIsCompanyChecked: PropTypes.func.isRequired,
};

export default MeetingList;