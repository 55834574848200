import PropTypes from 'prop-types';

import ConsultantsList from '../../containers/InfosPage/ConsultantsList';
import ModalAlert from './ModalAlert';

import './styles.scss';

const InfosPage = ({ areAlertsDisplayed }) => (
    <div className="infos-page-container">
        <h1 className="infos-page-title">Liste des consultants</h1>
        {areAlertsDisplayed
        &&
            <ModalAlert />
        }
        <div className='infos-page-consultants-lists-container'>
            <ConsultantsList companyValue={1} />
            <ConsultantsList companyValue={3} />
            <ConsultantsList companyValue={4} />
        </div>
    </div>
);

InfosPage.propTypes = {
    areAlertsDisplayed: PropTypes.bool,
};


InfosPage.defaultProps = {
    areAlertsDisplayed: true,
};

export default InfosPage;